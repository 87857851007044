import React from 'react'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import Modal from './Modal'
import Loader from './Loader'
import { GET_AI_RESULT } from './queries'

const AIResultModal = ({ onExit, aiResult }) => {
  const { loading, error, data } = useQuery(GET_AI_RESULT, {
    variables: {
      id: aiResult.id
    }
  })
  return (
    <Modal onClose={onExit}>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : !data ? (
        <p>
          This result isn&apos;t exist anymore, Probably was overwritten by a
          new result.
        </p>
      ) : (
        <>
          <div className='field is-horizontal'>
            <div className='field-label'>
              <label className='label has-text-left'>Result:</label>
            </div>
            <div className='field-body'>
              <div className='field'>
                <FontAwesomeIcon
                  icon={faCircle}
                  size='xs'
                  className={clsx({
                    'has-text-success': !aiResult.value,
                    'has-text-danger': aiResult.value
                  })}
                />
              </div>
            </div>
          </div>
          <div className='field is-horizontal'>
            <div className='field-label'>
              <label className='label has-text-left'>Study ID:</label>
            </div>
            <div className='field-body'>
              <div className='field'>{data.aiResult.studyId}</div>
            </div>
          </div>
          <div className='field is-horizontal'>
            <div className='field-label'>
              <label className='label has-text-left'>Record ID:</label>
            </div>
            <div className='field-body'>
              <div className='field'>{data.aiResult.recordId}</div>
            </div>
          </div>
          <div className='field is-horizontal'>
            <div className='field-label'>
              <label className='label has-text-left'>Date:</label>
            </div>
            <div className='field-body'>
              <div className='field'>
                {dayjs(data.aiResult.createdAt).format('DD-MM-YYYY HH:mm:ss')}
              </div>
            </div>
          </div>
          <div className='field'>
            <label className='label'>Data:</label>
            <div>
              <code style={{ whiteSpace: 'pre-wrap' }}>
                {JSON.stringify(JSON.parse(data.aiResult.data), null, 2)}
              </code>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

export default AIResultModal
