import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import useDebounce from '../hooks/useDebounce'
import usePagination from '../hooks/usePagination'
import AuthContext from './AuthContext'
import { GET_STUDIES, GET_STUDY_REPORT, GET_TAGS } from './queries'
import Layout from './Layout'
import Loader from './Loader'
import PatientSearch from './PatientSearch'
import { useLocation } from '@reach/router' // eslint-disable-line import/no-unresolved
import {
  base64UrlEncode,
  base64UrlDecode,
  durationToHuman,
  isAuthorizedAction,
  getParams,
  DATE_FORMAT_CONVERTER,
  localizePatientName,
  adjustTimeToTimezone,
  ANONYMOUS_PATIENT_NAME
} from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'
import SomethingWentWrong from './SomethingWentWrong'

const FIRST = 25

const Studies = ({ patientId, protocol, tag, navigate }) => {
  tag = tag ? base64UrlDecode(tag) : tag
  const currentUser = useContext(AuthContext)
  const { locale, interpolate } = useLocalization()
  const [filterBy, setFilterBy] = useState('filter_by_patient')
  const [fetchingReportStudyId, setFetchingReportStudyId] = useState(null)
  const client = useApolloClient()
  const [tagName, setTagName] = useState('')
  const debouncedTagName = useDebounce(tagName, 500)
  const [getTags, { loading: isSearchingTags, data: tags }] =
    useLazyQuery(GET_TAGS)
  useEffect(() => {
    if (debouncedTagName) {
      getTags({
        fetchPolicy: 'network-only',
        variables: { id: currentUser.id, prefix: debouncedTagName }
      })
    }
  }, [debouncedTagName])
  const [{ before, after, first }, setPagination] = usePagination(FIRST)
  const { loading, error, data } = useQuery(GET_STUDIES, {
    fetchPolicy: (!before && !after) || tag ? 'network-only' : 'cache-first',
    variables: {
      id: currentUser.id,
      patientId: patientId || '',
      protocol: protocol || null,
      tag: tag || null,
      first,
      before,
      after
    }
  })

  const { pathname, search } = useLocation()
  useEffect(() => {
    const queryParams = getParams(search)
    queryParams.filterBy &&
      setFilterBy(window.decodeURIComponent(queryParams.filterBy))
  }, [search])

  let studies = {
    edges: [],
    pageInfo: {}
  }

  if (error && error.message.indexOf('Invalid input for Enum') === 0) {
    return <SomethingWentWrong />
  }

  if (data && data.clinic && data.clinic.studies) {
    studies = data.clinic.studies
  }

  return (
    <Layout>
      <div className='section'>
        <div className='container is-flex is-justify-content-end studies-filters'>
          {(patientId && (
            <span className='tag is-primary is-medium'>
              {interpolate(locale.studies.filter_by_patient_value, {
                patient_id:
                  patientId === ANONYMOUS_PATIENT_NAME
                    ? patientId
                    : studies.edges.length > 0
                    ? studies.edges[0].node.patient.name +
                      (studies.edges[0].node.patient.owner
                        ? ` | ${studies.edges[0].node.patient.owner}`
                        : '') +
                      (studies.edges[0].node.patient.externalId
                        ? ` | ${studies.edges[0].node.patient.externalId}`
                        : '')
                    : ''
              })}
              <button
                className='delete'
                onClick={async () =>
                  await navigate(`/?filterBy=filter_by_patient`)
                }
              ></button>
            </span>
          )) ||
            (protocol && (
              <span className='tag is-primary is-medium'>
                {interpolate(locale.studies.filter_by_study_type_value, {
                  type: locale.studies.types[protocol.toLowerCase()] || protocol
                })}
                <button
                  className='delete'
                  onClick={async () =>
                    await navigate(`/?filterBy=filter_by_study_type`)
                  }
                ></button>
              </span>
            )) ||
            (tag && (
              <span className='tag is-primary is-medium'>
                {interpolate(locale.studies.filter_by_study_tag_value, {
                  tag: tag
                })}
                <button
                  className='delete'
                  onClick={async () =>
                    await navigate(`/?filterBy=filter_by_study_tag`)
                  }
                ></button>
              </span>
            )) || (
              <>
                <div className='control mr-2'>
                  <div className='select'>
                    <select
                      value={filterBy}
                      onChange={(e) => {
                        // Keep the other query params for the current pagination state
                        const params = getParams(search)
                        params['filterBy'] = e.target.value
                        var query = Object.keys(params)
                          .map(
                            (k) =>
                              encodeURIComponent(k) +
                              '=' +
                              encodeURIComponent(params[k])
                          )
                          .join('&')
                        navigate(`${pathname}?${query}`)
                      }}
                    >
                      {[
                        'filter_by_patient',
                        'filter_by_study_type',
                        'filter_by_study_tag'
                      ]
                        .filter(
                          // Filter by study tag only for veterinarian
                          (t) =>
                            t !== 'filter_by_study_tag' ||
                            currentUser.userType === 'Veterinarian'
                        )
                        .map((filterValue) => (
                          <option value={filterValue} key={filterValue}>
                            {locale.studies[filterValue]}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {filterBy === 'filter_by_study_type' && (
                  <div className='control'>
                    <div className='select'>
                      <select
                        value='default'
                        onChange={async (e) =>
                          await navigate(`/protocols/${e.target.value}`)
                        }
                      >
                        <option value='default' disabled>
                          {locale.studies.filter_by_study_type}
                        </option>
                        {[
                          'Rest',
                          'Holter',
                          'Questionnaire',
                          'Spirometer',
                          'Stethoscope',
                          'Attachments'
                        ]
                          .filter(
                            // Filter by spirometer only for physicians
                            (t) =>
                              t !== 'Spirometer' ||
                              currentUser.userType === 'Physician'
                          )
                          .map((protocol) => (
                            <option value={protocol} key={protocol}>
                              {locale.studies.types[protocol.toLowerCase()]}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
                {filterBy === 'filter_by_patient' && (
                  <PatientSearch
                    currentUser={currentUser}
                    locale={locale}
                    onPatientSelect={async (id) =>
                      await navigate(`/patients/${id}`)
                    }
                  />
                )}
                {filterBy === 'filter_by_study_tag' && (
                  <>
                    <div
                      className={clsx('dropdown', {
                        'is-active':
                          debouncedTagName &&
                          tags &&
                          tags.clinic.tags.edges.length > 0
                      })}
                    >
                      <div className='dropdown-trigger'>
                        <div
                          className={clsx('control', {
                            'is-loading': isSearchingTags
                          })}
                        >
                          <input
                            type='text'
                            className='input'
                            value={tagName}
                            onChange={(e) => setTagName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='dropdown-menu' role='menu'>
                        <div
                          className='dropdown-content'
                          style={{ maxHeight: 270, overflow: 'auto' }}
                        >
                          {debouncedTagName &&
                            tags &&
                            tags.clinic.tags.edges.map(({ node: { id } }) => (
                              <a
                                key={id}
                                className='dropdown-item'
                                onClick={async () => {
                                  setTagName('')
                                  return await navigate(
                                    `/tags/${base64UrlEncode(id)}`
                                  )
                                }}
                              >
                                {id}
                              </a>
                            ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
        </div>
        <div className='container mt-5'>
          {loading ? (
            <Loader />
          ) : (
            <>
              <table className='table is-fullwidth is-hoverable'>
                <thead>
                  <tr>
                    <th>{locale.studies.patient_name}</th>
                    <th>{locale.studies.patient_id}</th>
                    {currentUser.userType === 'Veterinarian' && (
                      <th>{locale.studies.animal_owner}</th>
                    )}
                    <th>{locale.shared_by}</th>
                    <th className='has-text-centered'>{locale.studies.type}</th>
                    <th className='has-text-centered'>{locale.studies.date}</th>
                    <th className='has-text-centered'>
                      {locale.studies.duration}
                    </th>
                    {isAuthorizedAction(
                      currentUser.clinic.authorizedActions,
                      'Clinic:ai'
                    ) && <th className='has-text-centered'>{locale.ai}</th>}
                    <th className='has-text-centered'>
                      {locale.studies.report}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {studies.edges.map(({ node: study }) => (
                    <tr
                      key={study.id}
                      className={clsx('c-hand', {
                        'has-text-weight-bold': !study.isSeen
                      })}
                      onClick={async () =>
                        await navigate(`/study/${study.studyId}`)
                      }
                    >
                      <td className='is-vcentered'>
                        {localizePatientName(study.patient.name, locale)}
                      </td>
                      <td className='is-vcentered'>
                        {study.patient.externalId}
                      </td>
                      {currentUser.userType === 'Veterinarian' && (
                        <td className='is-vcentered'>{study.patient.owner}</td>
                      )}
                      <td className='is-vcentered'>
                        {study.shareType !== 'ShareOriginal'
                          ? study.sourceCaregiver.name
                          : ''}
                      </td>
                      <td className='has-text-centered is-vcentered'>
                        {study.protocol}
                      </td>
                      <td className='has-text-centered is-vcentered'>
                        {adjustTimeToTimezone(
                          study.studyCreatedAt,
                          study.timezoneOffset
                        ).format(
                          `${
                            DATE_FORMAT_CONVERTER[
                              currentUser.settings.dateFormat
                            ]
                          } HH:mm:ss`
                        )}
                      </td>
                      <td className='has-text-centered is-vcentered'>
                        {['Rest', 'Holter', 'Stethoscope'].includes(
                          study.protocol
                        ) && study.duration
                          ? durationToHuman(study.duration)
                          : ''}
                      </td>
                      {isAuthorizedAction(
                        currentUser.clinic.authorizedActions,
                        'Clinic:ai'
                      ) && (
                        <td className='has-text-centered is-vcentered'>
                          {study.aiResult && (
                            <FontAwesomeIcon
                              icon={faCircle}
                              size='xs'
                              className={clsx({
                                'has-text-success': !study.aiResult.value,
                                'has-text-danger': study.aiResult.value
                              })}
                            />
                          )}
                        </td>
                      )}
                      <td className='has-text-centered is-vcentered'>
                        {study.hasReport && (
                          <button
                            className={clsx('button is-text', {
                              'is-loading':
                                study.studyId === fetchingReportStudyId
                            })}
                            onClick={async (e) => {
                              e.stopPropagation()
                              setFetchingReportStudyId(study.studyId)
                              const { data } = await client.query({
                                query: GET_STUDY_REPORT,
                                variables: {
                                  id: study.studyId
                                }
                              })
                              setFetchingReportStudyId(null)
                              if (
                                data &&
                                data.study &&
                                data.study.reports &&
                                data.study.reports.length > 0
                              ) {
                                await navigate(
                                  data.study.reports[0].privatePdf ||
                                    data.study.reports[0].publicPdf
                                    ? `/study/${study.studyId}?report=true`
                                    : `/study/${study.studyId}/report/${data.study.reports[0].id}`
                                )
                              }
                            }}
                          >
                            {locale.studies.view_report}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav
                className='pagination'
                role='navigation'
                aria-label='pagination'
              >
                <a
                  className='pagination-previous'
                  disabled={!studies.pageInfo.hasPreviousPage}
                  onClick={() => {
                    if (studies.pageInfo.hasPreviousPage) {
                      setPagination(studies.pageInfo.startCursor, null)
                    }
                  }}
                >
                  {locale.previous}
                </a>
                <a
                  className='pagination-next'
                  disabled={!studies.pageInfo.hasNextPage}
                  onClick={() => {
                    if (studies.pageInfo.hasNextPage) {
                      setPagination(
                        null,
                        studies.pageInfo.endCursor,
                        !studies.pageInfo.hasPreviousPage
                      )
                    }
                  }}
                >
                  {locale.next}
                </a>
              </nav>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Studies
