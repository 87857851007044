import React, { useState, useContext } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { navigate, useLocation } from '@reach/router' // eslint-disable-line import/no-unresolved
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import usePagination from '../hooks/usePagination'
import AuthContext from './AuthContext'
import { GET_REPORTS, GET_INVOICE_PAGE } from './queries'
import Layout from './Layout'
import Loader from './Loader'
// import PatientSearch from './PatientSearch'
import ReferralCancel from './ReferralCancel'
import Modal from './Modal'
import {
  DATE_FORMAT_CONVERTER,
  REFERRAL_TYPES_CONVERTER,
  isAuthorizedAction,
  localizePatientName,
  getParams
} from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'
import SomethingWentWrong from './SomethingWentWrong'

const FIRST = 50

const Reports = () => {
  const [{ before, after, first }, setPagination] = usePagination(FIRST)
  const currentUser = useContext(AuthContext)
  const { locale /*, interpolate */ } = useLocalization()
  const client = useApolloClient()
  const [referralToCancel, setReferralToCancel] = useState(null)
  const [fetchingReferralId, setFetchingReferralId] = useState(null)
  const [isInvoicePageNotFound, setIsInvoicePageNotFound] = useState(false)
  const { search } = useLocation()
  const queryParams = getParams(search)
  const patientId = queryParams.filter_by_patient
    ? decodeURIComponent(queryParams.filter_by_patient)
    : null
  const { loading, error, data } = useQuery(GET_REPORTS, {
    variables: {
      id: currentUser.id,
      patientId,
      first,
      before,
      after
    },
    skip: !isAuthorizedAction(
      currentUser.clinic.authorizedActions,
      'Clinic:sourceReferrals'
    )
  })

  if (error) return <SomethingWentWrong />

  const referrals = loading
    ? []
    : data.clinic.sourceReferrals.edges.map(({ node }) => node)

  return (
    <Layout>
      {isAuthorizedAction(
        currentUser.clinic.authorizedActions,
        'Clinic:sourceReferrals'
      ) && (
        <div className='section'>
          {/* <div className='container is-flex is-justify-content-end studies-filters'>
            {(patientId && (
              <span className='tag is-primary is-medium'>
                {interpolate(locale.studies.filter_by_patient_value, {
                  patient_id:
                    referrals.length > 0
                      ? referrals[0].patient.name +
                        (referrals[0].patient.owner
                          ? ` | ${referrals[0].patient.owner}`
                          : '')
                      : ''
                })}
                <button
                  className='delete'
                  onClick={async () => await navigate(`/reports`)}
                ></button>
              </span>
            )) || (
              <>
                <div className='label mr-4 is-flex is-align-items-center'>
                  {locale.studies.filter_by_patient}:
                </div>
                <PatientSearch
                  currentUser={currentUser}
                  locale={locale}
                  ignoreAnonymous
                  showNameAndOwnerOnly
                  onPatientSelect={async (id) =>
                    await navigate(
                      `/reports?filter_by_patient=` + encodeURIComponent(id)
                    )
                  }
                />
              </>
            )}
          </div> */}
          {/* <div className='container mt-5'> */}
          <div className='container'>
            {loading ? (
              <Loader />
            ) : (
              <>
                {referralToCancel && (
                  <ReferralCancel
                    referral={referralToCancel}
                    onComplete={() => {
                      setReferralToCancel(null)
                    }}
                    onExit={() => setReferralToCancel(null)}
                    onUpdate={(
                      cache,
                      {
                        data: {
                          studyReferralCancel: { id }
                        }
                      }
                    ) => {
                      cache.modify({
                        id: cache.identify(currentUser.clinic),
                        fields: {
                          sourceReferrals: ({ edges, pageInfo }) => ({
                            edges: edges.filter(({ node }) => node.id !== id),
                            pageInfo
                          })
                        }
                      })
                      cache.modify({
                        id: `Study:${referralToCancel.studyId}`,
                        fields: {
                          referrals: (referrals) =>
                            referrals.filter((r) => r.id !== id)
                        }
                      })
                    }}
                  ></ReferralCancel>
                )}
                {isInvoicePageNotFound && (
                  <Modal onClose={() => setIsInvoicePageNotFound(false)}>
                    <div className='field'>
                      <label className='label'>
                        {locale.report_requests.invoice_not_found}
                      </label>
                      <div>
                        <span>
                          {
                            locale.report_requests
                              .invoice_not_found_contact_support
                          }
                        </span>{' '}
                        <a
                          href='mailto:billing@beecardia.com'
                          target='_blank'
                          rel='noreferrer'
                        >
                          billing@beecardia.com
                        </a>
                      </div>
                      <div className='has-text-right'>
                        <button
                          className='button is-primary mt-4'
                          onClick={() => setIsInvoicePageNotFound(false)}
                        >
                          {locale.ok}
                        </button>
                      </div>
                    </div>
                  </Modal>
                )}
                <div className='table-container'>
                  <table className='table is-fullwidth is-hoverable'>
                    <thead>
                      <tr>
                        <th>{locale.report_requests.ordered_from}</th>
                        <th>{locale.studies.patient_name}</th>
                        {currentUser.userType === 'Veterinarian' && (
                          <th>{locale.studies.animal_owner}</th>
                        )}
                        <th>{locale.studies.type}</th>
                        <th>{locale.studies.date}</th>
                        <th>{locale.status}</th>
                        {isAuthorizedAction(
                          currentUser.clinic.authorizedActions,
                          'Clinic:ai'
                        ) && <th className='has-text-centered'>{locale.ai}</th>}
                        <th style={{ paddingLeft: 'calc(0.75em + 1em + 1px)' }}>
                          {locale.report_requests.actions}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {referrals.length === 0 && (
                        <tr>
                          <td
                            colSpan={10}
                            className='has-text-centered is-vcentered'
                            dangerouslySetInnerHTML={{
                              __html: locale.report_requests.explanation
                            }}
                          />
                        </tr>
                      )}
                      {referrals.map((referral) => (
                        <tr
                          key={referral.studyId}
                          className='c-hand'
                          onClick={() => navigate(`/study/${referral.studyId}`)}
                        >
                          <td className='is-vcentered'>
                            {referral.targetQueue
                              ? referral.targetQueue.name
                              : referral.targetCaregiver.name}
                          </td>
                          <td className='is-vcentered'>
                            {localizePatientName(referral.patient.name, locale)}
                          </td>
                          {currentUser.userType === 'Veterinarian' && (
                            <td className='is-vcentered'>
                              {referral.patient.owner}
                            </td>
                          )}
                          <td className='is-vcentered'>
                            {
                              locale.studies.reports[
                                REFERRAL_TYPES_CONVERTER[referral.referralType]
                              ]
                            }
                          </td>
                          <td className='is-vcentered'>
                            {dayjs(referral.createdAt).format(
                              `${
                                DATE_FORMAT_CONVERTER[
                                  currentUser.settings.dateFormat
                                ]
                              } HH:mm:ss`
                            )}
                          </td>
                          <td className='is-vcentered'>
                            {
                              locale.referrals.status[
                                referral.status === 'PendingInQueue'
                                  ? 'Pending'
                                  : referral.status
                              ]
                            }
                          </td>
                          {isAuthorizedAction(
                            currentUser.clinic.authorizedActions,
                            'Clinic:ai'
                          ) && (
                            <td className='has-text-centered is-vcentered'>
                              {referral.aiResult && (
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  size='xs'
                                  className={clsx({
                                    'has-text-success':
                                      !referral.aiResult.value,
                                    'has-text-danger': referral.aiResult.value
                                  })}
                                />
                              )}
                            </td>
                          )}
                          <td className='is-vcentered is-align-items-center'>
                            <div className='is-flex is-align-items-center'>
                              {[
                                referral.status === 'Done' ? (
                                  <button
                                    key={referral.stydyId + '_view_report'}
                                    className='button is-text'
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      navigate(
                                        `/study/${referral.studyId}/report/${referral.reportId}`
                                      )
                                    }}
                                  >
                                    {locale.view_report}
                                  </button>
                                ) : null,
                                referral.status === 'PaymentPending' ? (
                                  <button
                                    key={referral.stydyId + '_complete_payment'}
                                    className='button is-text'
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      navigate(
                                        `/study/${
                                          referral.studyId
                                        }?paymentRequest=1&targetCaregiverId=${
                                          referral.targetQueue
                                            ? referral.targetQueue.id
                                            : referral.targetCaregiver.id
                                        }&referralId=${
                                          referral.id
                                        }&referralType=${referral.referralType}`
                                      )
                                    }}
                                  >
                                    {locale.report_requests.complete_payment}
                                  </button>
                                ) : null,
                                referral.status === 'Done' &&
                                referral.price > 0 ? (
                                  <span
                                    key={referral.stydyId + '_1'}
                                    className='ml-2 mr-2'
                                  >
                                    |
                                  </span>
                                ) : null,
                                referral.status !== 'PaymentPending' &&
                                referral.price > 0 ? (
                                  <button
                                    key={referral.stydyId + '_download_invoice'}
                                    className={clsx('button is-text', {
                                      'is-loading':
                                        referral.id === fetchingReferralId
                                    })}
                                    onClick={async (e) => {
                                      e.stopPropagation()
                                      setFetchingReferralId(referral.id)
                                      const { data } = await client.query({
                                        query: GET_INVOICE_PAGE,
                                        variables: {
                                          id: referral.id
                                        }
                                      })
                                      setFetchingReferralId(null)
                                      if (
                                        data &&
                                        data.referral &&
                                        data.referral.invoicePage
                                      ) {
                                        window.open(
                                          data.referral.invoicePage,
                                          '_blank'
                                        )
                                      } else if (
                                        data &&
                                        data.referral &&
                                        !data.referral.invoicePage
                                      ) {
                                        setIsInvoicePageNotFound(true)
                                      }
                                    }}
                                  >
                                    {locale.report_requests.download_invoice}
                                  </button>
                                ) : null,
                                referral.status !== 'Done' &&
                                (referral.status === 'PaymentPending' ||
                                  (referral.status !== 'PaymentPending' &&
                                    referral.price > 0)) ? (
                                  <span
                                    key={referral.stydyId + '_2'}
                                    className='ml-2 mr-2'
                                  >
                                    |
                                  </span>
                                ) : null,
                                referral.status !== 'Done' ? (
                                  <button
                                    key={referral.stydyId + '_cancel_report'}
                                    className='button is-text'
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setReferralToCancel(referral)
                                    }}
                                  >
                                    {locale.report_requests.cancel_report}
                                  </button>
                                ) : null
                              ].filter((v) => v)}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <nav
                  className='pagination'
                  role='navigation'
                  aria-label='pagination'
                >
                  <a
                    className='pagination-previous'
                    disabled={
                      !data.clinic.sourceReferrals.pageInfo.hasPreviousPage
                    }
                    onClick={() => {
                      if (
                        data.clinic.sourceReferrals.pageInfo.hasPreviousPage
                      ) {
                        setPagination(
                          data.clinic.sourceReferrals.pageInfo.startCursor,
                          null
                        )
                      }
                    }}
                  >
                    {locale.previous}
                  </a>
                  <a
                    className='pagination-next'
                    disabled={!data.clinic.sourceReferrals.pageInfo.hasNextPage}
                    onClick={() => {
                      if (data.clinic.sourceReferrals.pageInfo.hasNextPage) {
                        setPagination(
                          null,
                          data.clinic.sourceReferrals.pageInfo.endCursor
                        )
                      }
                    }}
                  >
                    {locale.next}
                  </a>
                </nav>
              </>
            )}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Reports
