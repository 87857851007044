import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Modal from '../Modal'
import clsx from 'clsx'
import {
  GET_REFERRALS_CONFIG,
  GET_REFERRALS_CONFIGS_WITH_DATA
} from '../queries'

const GraphqlSchemaEnums = JSON.parse(process.env.GraphqlSchemaEnums)

const UPDATE_REFERRAL_CONFIG = gql`
  mutation UPDATE_REFERRAL_CONFIG(
    $id: ID!
    $group: String!
    $studyProtocol: StudyProtocol!
    $referralType: ReferralType!
    $input: UserUpdateInReferralsConfigInput!
  ) {
    userUpdateInReferralsConfig(
      id: $id
      group: $group
      studyProtocol: $studyProtocol
      referralType: $referralType
      input: $input
    ) {
      id
    }
  }
`

const UpdateReferralConfig = ({ group, configToEdit, onExit, onComplete }) => {
  const [inProgress, setProgress] = useState(false)
  const [priceInput, setPriceInput] = useState(configToEdit.price)
  const [currencyInput, setCurrencyInput] = useState(configToEdit.currency)

  const [userUpdateInReferralsConfig, { loading: isEditing }] = useMutation(
    UPDATE_REFERRAL_CONFIG,
    {
      refetchQueries: [
        {
          query: GET_REFERRALS_CONFIG,
          variables: { referralsConfigId: group }
        },
        {
          query: GET_REFERRALS_CONFIGS_WITH_DATA
        }
      ],
      awaitRefetchQueries: true
    }
  )

  async function submit() {
    setProgress(true)

    await userUpdateInReferralsConfig({
      variables: {
        id: configToEdit.caregiver.id,
        group: group,
        studyProtocol: configToEdit.studyProtocol,
        referralType: configToEdit.referralType,
        input: {
          currency: currencyInput,
          price: priceInput,
          updatedAt: new Date().toISOString()
        }
      }
    })
    setProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Group:</label>
        </div>
        <div className='field-body'>
          <div className='field'>{group}</div>
        </div>
      </div>
      {configToEdit.isQueue ? (
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Queue:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>{configToEdit.caregiver.name}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Caregiver:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>{configToEdit.caregiver.name}</div>
            </div>
          </div>
        </div>
      )}
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Protocol:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>{configToEdit.studyProtocol}</div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Type:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>{configToEdit.referralType}</div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Price:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <input
                className='input'
                type='number'
                placeholder='Caregiver email'
                value={priceInput}
                min='0'
                onChange={(e) => setPriceInput(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Currency:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <div className='select'>
                <select
                  value={currencyInput}
                  onChange={(e) => setCurrencyInput(e.target.value)}
                >
                  <option disabled='disabled' value='-1'>
                    Select currency
                  </option>
                  {GraphqlSchemaEnums.CurrencyType.map((currency) => (
                    <option value={currency} key={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='level'>
        <div className='level-left'>
          <div className='level-item'></div>
        </div>
        <div className='level-right'>
          <div className='level-item'>
            <button
              className={clsx('button is-primary', {
                'is-loading': inProgress || isEditing
              })}
              onClick={async () => submit()}
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateReferralConfig
