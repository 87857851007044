import React, { useState, useRef, useEffect } from 'react'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DiagnosisTags from './DiagnosisTags'
import { useCombobox } from 'downshift'
import { navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
import { base64UrlEncode } from '../../utils/utils'

const TagsInput = ({ tags, dispatchTags, isRest }) => {
  const [diagnosisTagsText, setDiagnosisTagsText] = useState([])
  const [isDiagnosisTagsMode, setDiagnosisTagsMode] = useState(false)
  const [tagsTextCursorPosition, setTagsTextCursorPosition] = useState(null)

  useEffect(() => {
    if (tagsTextCursorPosition !== null) {
      tagsInput.current.setSelectionRange(
        tagsTextCursorPosition,
        tagsTextCursorPosition
      )
      setTagsTextCursorPosition(null)
    }
  }, [tagsTextCursorPosition])

  const autoCompleteDictionary = {
    rest: [
      'Aberrant conduction',
      'Arrhythmogenic right ventricular tachycardia',
      'Atrial fibrillation',
      'Atrial flutter',
      'Atrial premature complexes',
      'Atrial tachycardia',
      'Atrioventricular nodal reciprocating tachycardia',
      'AV-Block',
      'AV-Block 1st degree',
      'AV-Block 2nd degree',
      'AV-Block Mobitz type I',
      'AV-Block Mobitz type II',
      'Bradyarrhythmia',
      'Bundle branch block',
      'Chamber enlargement',
      'Escape rhythm',
      'Focal atrial tachycardia',
      'Fusion complexes',
      'Idioventricular rhythm',
      'Isorhythmic atrioventricular dissociation',
      'Left atrial enlargement',
      'Left bundle branch block',
      'Left ventricular enlargement',
      'Left ventricular escape rhythm',
      'Monomorphic ventricular tachycardia',
      'Monomorphic ventricular tachycardia (LV focus)',
      'Monomorphic ventricular tachycardia (RV focus)',
      'Nodal escape rhythm',
      'Nodal tachycardia',
      'Normal respiratory sinus arrhythmia',
      'Normal sinus arrhythmia',
      'Normal sinus rhythm',
      'Orthodromic atrioventricular reciprocating tachycardia',
      'Polymorphic ventricular tachycardia',
      'R-on-T phenomenon',
      'Right atrial enlargement',
      'Right bundle branch block',
      'Right ventricular enlargement',
      'Right ventricular escape rhythm',
      'Sick sinus syndrome',
      'Supraventricular tachycardia with aberrant ventricular conduction',
      'Sustained monomorphic ventricular tachycardia',
      'Sustained polymorphic ventricular tachycardia',
      'Tachyarrhythmia',
      'Ventricular bigeminy',
      'Ventricular escape rhythm',
      'Ventricular premature complexes',
      'Ventricular tachycardia',
      'Ventricular trigeminy',
      'Wide complex tachycardia'
    ]
  }

  const tagsInput = useRef()
  const autoCompleteItems = isRest ? autoCompleteDictionary.rest : []
  const [inputItems, setInputItems] = useState(autoCompleteItems)
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    closeMenu
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      let pos = tagsInput.current.selectionStart
      let sub = inputValue.substring(0, pos)
      let val = sub.substring(sub.lastIndexOf(',') + 1, pos).trim()

      if (val === '') {
        closeMenu()
        return
      }

      setInputItems(
        autoCompleteItems.filter((item) =>
          item.toLowerCase().startsWith(val.toLowerCase())
        )
      )
    },
    onSelectedItemChange: (changes) => {
      let item = changes.selectedItem
      if (item == '' || item == null) return

      let currentValue = tagsInput.current.value
      let firstPart = currentValue.substring(
        0,
        tagsInput.current.selectionStart
      )
      let lastComma = firstPart.lastIndexOf(',')
      let newStart =
        (lastComma != -1 ? firstPart.substring(0, lastComma) + ', ' : '') + item
      let newValue =
        newStart + currentValue.substring(firstPart.length, currentValue.length)

      setTagsTextCursorPosition(newStart.length)
      setDiagnosisTagsText(newValue)
      selectItem('')
    }
  })

  function addDiagnosisTags() {
    let tagsArr = (diagnosisTagsText || '').split(',')
    const tmpArr = []
    tagsArr.forEach((tag) => {
      tag = tag.trim().toLowerCase()
      if (tag !== '' && !tags.includes(tag) && !tmpArr.includes(tag)) {
        tmpArr.push(tag)
      }
    })
    dispatchTags([...tags, ...tmpArr])
  }

  return (
    <>
      {isDiagnosisTagsMode && (
        <DiagnosisTags
          diagnosisTags={tags}
          onComplete={(tags) => {
            dispatchTags(tags)
            setDiagnosisTagsMode(false)
          }}
          onExit={() => setDiagnosisTagsMode(false)}
        />
      )}
      <>
        <div>
          <div className='field control is-expanded has-addons mt-2'>
            <div className='control is-expanded'>
              <input
                className='input'
                {...getInputProps({
                  onKeyDown: (e) => {
                    if (e.keyCode === 13 && highlightedIndex === -1) {
                      addDiagnosisTags()
                      setDiagnosisTagsText('')
                    }
                  },
                  onChange: (e) => {
                    setDiagnosisTagsText(e.target.value)
                  },
                  ref: tagsInput
                })}
                value={diagnosisTagsText}
              />
            </div>
            {isRest && (
              <div className='control'>
                <button
                  className='button is-primary is-outlined'
                  onClick={() => setDiagnosisTagsMode(true)}
                >
                  <FontAwesomeIcon icon={faEllipsisH} />
                </button>
              </div>
            )}
          </div>
          <aside {...getMenuProps()} className='menu autocomplete-menu-wrapper'>
            <ul className='menu-list' id='tags-autocomplete-menu'>
              {isOpen &&
                inputItems.map((item, index) => (
                  <li
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                  >
                    <a
                      className={highlightedIndex === index ? 'is-active' : ''}
                    >
                      {item}
                    </a>
                  </li>
                ))}
            </ul>
          </aside>
        </div>
        <div className='field is-grouped is-grouped-multiline mt-5'>
          {tags.map((tag) => (
            <div key={tag} className='control'>
              <div className='tags has-addons'>
                <a
                  className='tag is-primary'
                  onClick={() => navigate(`/tags/${base64UrlEncode(tag)}`)}
                >
                  {tag}
                </a>
                <a
                  className='tag is-delete is-primary'
                  onClick={() => {
                    var _tags = [...tags]
                    _tags.splice(_tags.indexOf(tag), 1)
                    dispatchTags(_tags)
                  }}
                ></a>
              </div>
            </div>
          ))}
        </div>
      </>
    </>
  )
}

export default TagsInput
