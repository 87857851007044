import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useContext
} from 'react'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import MeasurementValues from './MeasurementValues'
import AuthContext from './AuthContext'
import TagsInput from './TagsInput'
import { useLocalization } from './LocalizationProvider'
import { isRTL, interpolateConclusionTemplate } from '../../utils/utils'

const MAX_TAGS_NUMBER = 24

const CREATE_DIAGNOSIS = gql`
  mutation CREATE_DIAGNOSIS(
    $studyId: ID!
    $clinicId: ID!
    $caregiver: CaregiverConnectionInput!
    $clinic: ClinicConnectionInput!
    $input: StudyDiagnosisCreateInput!
  ) {
    studyDiagnosisCreate(
      studyId: $studyId
      caregiver: $caregiver
      clinic: $clinic
      input: $input
    ) {
      id
    }
    query {
      study(id: $studyId) {
        id
        diagnosis(clinicId: $clinicId) {
          id
          updatedAt
          measurements
          mea
          conclusion
          tags
          caregiver {
            name
          }
        }
      }
    }
  }
`

const UPDATE_DIAGNOSIS = gql`
  mutation UPDATE_DIAGNOSIS(
    $caregiver: CaregiverConnectionInput!
    $studyId: ID!
    $clinicId: ID!
    $input: StudyDiagnosisUpdateInput!
  ) {
    studyDiagnosisUpdate(caregiver: $caregiver, input: $input) {
      id
    }
    query {
      study(id: $studyId) {
        id
        diagnosis(clinicId: $clinicId) {
          id
          updatedAt
          measurements
          mea
          conclusion
          tags
          caregiver {
            name
          }
        }
      }
    }
  }
`

const initialState = {
  measurements: JSON.stringify({
    HR: '',
    P: '',
    P_MV: '',
    PR: '',
    QRS: '',
    QT: '',
    R: ''
  }),
  mea: null,
  conclusion: '',
  tags: []
}

function reducerBuilder(state, [key, ...keys], payload) {
  if (keys.length === 0) {
    return {
      ...state,
      [key]: payload
    }
  }
  return {
    ...state,
    [key]: reducerBuilder(state[key], keys, payload)
  }
}

function reducer(state, action) {
  if (action.type === 'RESET') return action.payload
  return reducerBuilder(state, action.key.split('.'), action.payload)
}

const DiagnosisTab = ({
  isRest,
  diagnosis,
  userMarks,
  initialMEA,
  initialMeasurements,
  dateFormat,
  animalType,
  studyId,
  forceReRender,
  setTab,
  setTimePositionAfterTabChange,
  onDiagnosisCreate
}) => {
  const currentUser = useContext(AuthContext)
  const { locale, interpolate } = useLocalization()
  const [conclusionTemplates] = useState(
    currentUser.settings.reportConclusionTemplates
      ? JSON.parse(currentUser.settings.reportConclusionTemplates)
      : null
  )
  const [diagnosisData, dispatchDiagnosisData] = useReducer(
    reducer,
    initialState
  )

  const initialConclusion =
    !(diagnosis && typeof diagnosis.conclusion === 'string') &&
    conclusionTemplates &&
    conclusionTemplates.default_template
      ? interpolateConclusionTemplate(
          conclusionTemplates.list[conclusionTemplates.default_template].text,
          currentUser
        )
      : diagnosis
      ? diagnosis.conclusion
      : ''
  useEffect(() => {
    // Avoid closure issues
    const _initialConclusion =
      !(diagnosis && typeof diagnosis.conclusion === 'string') &&
      conclusionTemplates &&
      conclusionTemplates.default_template
        ? interpolateConclusionTemplate(
            conclusionTemplates.list[conclusionTemplates.default_template].text,
            currentUser
          )
        : diagnosis
        ? diagnosis.conclusion
        : ''
    dispatchDiagnosisData({
      type: 'RESET',
      payload: {
        measurements: JSON.parse(
          (diagnosis || initialMeasurements || initialState).measurements
        ),
        mea: (diagnosis || initialMEA || initialState).mea,
        conclusion: _initialConclusion,
        tags: (diagnosis || initialState).tags
      }
    })
  }, [studyId])
  useEffect(() => {
    dispatchDiagnosisData({
      key: 'mea',
      payload: (diagnosis || initialMEA || initialState).mea
    })
  }, [initialMEA])
  useEffect(() => {
    dispatchDiagnosisData({
      key: 'measurements',
      payload: JSON.parse(
        (diagnosis || initialMeasurements || initialState).measurements
      )
    })
  }, [initialMeasurements])

  const conclusionTextarea = useRef()
  // move textarea cursor to end so the first template insertion will be to the end
  useEffect(() => {
    if (conclusionTextarea && conclusionTextarea.current) {
      conclusionTextarea.current.setSelectionRange(
        diagnosisData.conclusion.length,
        diagnosisData.conclusion.length
      )
    }
  }, [conclusionTextarea])

  const [inProgressUpdateLegacyClinic, setInProgressUpdateLegacyClinic] =
    useState(false)
  const [createDiagnosis, { loading: createDiagnosisLoading }] =
    useMutation(CREATE_DIAGNOSIS)
  const [updateDiagnosis, { loading: updateDiagnosisLoading }] =
    useMutation(UPDATE_DIAGNOSIS)

  const hasDiagnosis = !!diagnosis
  const defaultData = diagnosis || initialState

  const measurementsBeforeChange = JSON.parse(
    (diagnosis || initialMeasurements || initialState).measurements
  )
  const hasMeasurementsChanged =
    JSON.stringify(
      Object.keys(measurementsBeforeChange)
        .sort()
        .reduce((obj, key) => {
          let val = measurementsBeforeChange[key].toString()
          if (!(val === '')) {
            obj[key] = val
          }
          return obj
        }, {})
    ) !==
    JSON.stringify(
      Object.keys(diagnosisData.measurements)
        .sort()
        .reduce((obj, key) => {
          let val = diagnosisData.measurements[key].toString()
          if (!(val === '')) {
            obj[key] = val
          }
          return obj
        }, {})
    )
  const hasMeaChanged =
    (diagnosis || initialMEA || initialState).mea !== diagnosisData.mea

  const hasConclusionChanged = initialConclusion !== diagnosisData.conclusion
  const hasTagsChanged =
    JSON.stringify(defaultData.tags) !== JSON.stringify(diagnosisData.tags)

  const hasFormChanged =
    hasMeasurementsChanged ||
    hasMeaChanged ||
    hasConclusionChanged ||
    hasTagsChanged

  return (
    <>
      <div className='is-small'>
        {isRest && (
          <div className='diagnosis-section'>
            <div className='mb-4'>
              <span className='title is-5'>{locale.measurements}</span>
            </div>
            <MeasurementValues
              measurements={diagnosisData.measurements}
              mea={diagnosisData.mea}
              dispatch={dispatchDiagnosisData}
              animalType={animalType}
            />
            <div
              className={clsx('help is-danger mt-4 ', {
                'is-hidden': !(hasMeasurementsChanged || hasMeaChanged)
              })}
            >
              {locale.save_warning}
            </div>
          </div>
        )}

        <div className='diagnosis-section'>
          <div className='mb-4'>
            <span className='title is-5'>{locale.conclusions}</span>
            {conclusionTemplates !== null && (
              <select
                id='select_conclusion_template'
                onChange={(e) => {
                  if (e.target.value === '-1') return
                  const textToInsert = interpolateConclusionTemplate(
                    conclusionTemplates.list[e.target.value].text,
                    currentUser
                  )
                  const cursorPosition =
                    conclusionTextarea.current.selectionStart
                  const textBeforeCursorPosition =
                    diagnosisData.conclusion.slice(0, cursorPosition)
                  const textAfterCursorPosition =
                    diagnosisData.conclusion.slice(cursorPosition)
                  dispatchDiagnosisData({
                    key: 'conclusion',
                    payload:
                      textBeforeCursorPosition +
                      textToInsert +
                      textAfterCursorPosition
                  })
                }}
                value='-1'
              >
                <option disabled='disabled' value='-1'>
                  {locale.report.insert_template}
                </option>
                {Object.entries(conclusionTemplates.list).map(
                  ([key, template]) => (
                    <option key={key} value={key}>
                      {template.name}
                    </option>
                  )
                )}
              </select>
            )}
          </div>
          <textarea
            className='textarea mt-2'
            maxLength={10000}
            rows='10'
            value={diagnosisData.conclusion}
            style={{
              direction:
                diagnosisData.conclusion && isRTL(diagnosisData.conclusion[0])
                  ? 'rtl'
                  : 'ltr'
            }}
            onChange={(e) =>
              dispatchDiagnosisData({
                key: 'conclusion',
                payload: e.target.value
              })
            }
            ref={conclusionTextarea}
          />
          <div className='is-flex is-justify-content-space-between'>
            <span id='report_signature_creator'>
              {hasDiagnosis ? diagnosis.caregiver.name : ''}
            </span>
            <span id='report_signature_date' className='report_signature_date'>
              {hasDiagnosis
                ? dayjs(diagnosis.updatedAt).format(dateFormat)
                : ''}
            </span>
          </div>
          <div
            className={clsx('help is-danger mt-4', {
              'is-hidden': !hasConclusionChanged
            })}
          >
            {locale.save_warning}
          </div>
        </div>

        <div className='diagnosis-section'>
          <div className='mb-4'>
            <span className='title is-5'>{locale.diagnosis_tags}</span>
          </div>
          <TagsInput
            tags={diagnosisData.tags}
            dispatchTags={(tags) =>
              dispatchDiagnosisData({
                key: 'tags',
                payload: tags
              })
            }
            isRest={isRest}
          />
          <div
            className={clsx('help is-danger mt-4', {
              'is-hidden': !hasTagsChanged
            })}
          >
            {locale.save_warning}
          </div>
        </div>
        {userMarks.length > 0 && (
          <div className='diagnosis-section'>
            <div className='mb-4'>
              <span className='title is-5'>{locale.marks}</span>
            </div>
            <div id='marks-list'>
              {userMarks
                .sort((m1, m2) => (m2.x < m1.x ? 1 : -1))
                .map((mark) => (
                  <div className='level' key={mark.x}>
                    <div className='level-item level-left'>
                      <div className='mark-comment'>
                        {mark.comment === ''
                          ? locale.no_description
                          : mark.comment}
                      </div>
                    </div>
                    <div className='level-item level-right'>
                      <button
                        className='button is-primary is-small'
                        onClick={() => {
                          setTab('RecordEcgRestData')
                          setTimePositionAfterTabChange(mark.x)
                        }}
                      >
                        {locale.view}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className='level'>
          <div className='level-left'>
            <div className='level-item'>
              <button
                className={clsx('button is-primary', {
                  'is-loading':
                    createDiagnosisLoading ||
                    updateDiagnosisLoading ||
                    inProgressUpdateLegacyClinic
                })}
                disabled={
                  !hasFormChanged ||
                  createDiagnosisLoading ||
                  updateDiagnosisLoading ||
                  inProgressUpdateLegacyClinic ||
                  diagnosisData.tags.length > MAX_TAGS_NUMBER
                }
                onClick={async () => {
                  const createdAt = new Date().toISOString()

                  /* TODO: remove after migration */
                  try {
                    setInProgressUpdateLegacyClinic(true)
                    const iframe = document.createElement('iframe')
                    iframe.setAttribute(
                      'src',
                      window.location.origin + '/en/account/edit_profile'
                    )
                    iframe.setAttribute('hidden', 'hidden')
                    document.getElementsByTagName('body')[0].appendChild(iframe)
                    await new Promise((resolve) =>
                      iframe.addEventListener('load', resolve)
                    )
                    const csrfToken = iframe.contentWindow.document
                      .querySelector("meta[name='csrf-token']")
                      .getAttribute('content')
                    iframe.parentNode.removeChild(iframe)

                    const body = new URLSearchParams({
                      hash_id: studyId,
                      conclusion: diagnosisData.conclusion,
                      measurement_values: JSON.stringify(
                        Object.keys(diagnosisData.measurements).reduce(
                          (obj, key) => (
                            (obj[key] = diagnosisData.measurements[key] || ''),
                            obj
                          ),
                          {}
                        )
                      ),
                      mea_label: {
                        '': 'novalue',
                        null: 'novalue',
                        undefined: 'novalue',
                        Normal: 'normal',
                        Mild: 'mild_right_axis_deviation',
                        Moderate: 'moderate_right_axis_deviation',
                        Severe: 'severe_right_axis_deviation',
                        Right: 'right_axis_deviation',
                        Left: 'left_axis_deviation'
                      }[diagnosisData.mea],
                      is_request_from_new_app: true
                    })

                    diagnosisData.tags.forEach((tag) =>
                      body.append('tags[]', tag)
                    )

                    const response = await window.fetch(`/api/add_diagnosis`, {
                      method: 'POST',
                      headers: {
                        'Content-Type':
                          'application/x-www-form-urlencoded; charset=UTF-8',
                        'X-CSRF-Token': csrfToken
                      },
                      body
                    })
                    const result = await response.json()
                    console.log('add_diagnosis result:', result)
                    setInProgressUpdateLegacyClinic(false)
                  } catch (e) {
                    console.error(e)
                    setInProgressUpdateLegacyClinic(false)
                  }

                  if (hasDiagnosis) {
                    await updateDiagnosis({
                      variables: {
                        caregiver: {
                          id: currentUser.id,
                          name: currentUser.settings.displayName,
                          email: currentUser.email
                        },
                        input: {
                          id: diagnosis.id,
                          updatedAt: createdAt,
                          ...diagnosisData,
                          measurements: JSON.stringify(
                            diagnosisData.measurements
                          )
                        },
                        studyId,
                        clinicId: currentUser.clinic.id
                      }
                    })
                  } else {
                    await createDiagnosis({
                      variables: {
                        studyId,
                        clinicId: currentUser.clinic.id,
                        caregiver: {
                          id: currentUser.id,
                          name: currentUser.settings.displayName,
                          email: currentUser.email
                        },
                        clinic: {
                          id: currentUser.clinic.id,
                          name: currentUser.clinic.name
                        },
                        input: {
                          id: uuidv4(),
                          createdAt,
                          ...diagnosisData,
                          measurements: JSON.stringify(
                            diagnosisData.measurements
                          )
                        }
                      }
                    })
                    onDiagnosisCreate && onDiagnosisCreate(diagnosisData)
                  }
                  forceReRender()
                }}
              >
                {locale.save}
              </button>
            </div>
            <div className='level-item'>
              <label
                className={clsx('has-text-danger', {
                  'is-hidden': diagnosisData.tags.length <= MAX_TAGS_NUMBER
                })}
              >
                {interpolate(locale.max_tags_error, {
                  tags_count: diagnosisData.tags.length,
                  max_tags_number: MAX_TAGS_NUMBER
                })}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DiagnosisTab
