import React, { useState } from 'react'
import { GET_AI_FEEDBACKS } from '../queries'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import AIResultModal from '../AIResultModal'
import useMemoryPagination, {
  prev,
  next
} from '../../hooks/useMemoryPagination'
import dayjs from 'dayjs'

const FIRST = 20

const AdminAIFeedbacks = () => {
  const [isShowAIResultModal, setIsShowAIResultModal] = useState(false)

  const [paginationState, dispatch] = useMemoryPagination()

  const [expandedNotesId, setExpandedNotesId] = useState(null)

  const { loading, error, data } = useQuery(GET_AI_FEEDBACKS, {
    variables: {
      first: FIRST,
      after: paginationState
    }
  })

  return (
    <div className='mt-5'>
      {isShowAIResultModal && (
        <AIResultModal
          onExit={() => setIsShowAIResultModal(false)}
          aiResult={isShowAIResultModal}
        />
      )}
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}
      {data && (
        <>
          <div className='table-container'>
            <table className='table is-fullwidth is-hoverable'>
              <thead>
                <tr>
                  <th className='is-narrow'>Created By</th>
                  <th className='is-narrow'>Created At</th>
                  <th className='is-narrow has-text-centered'>Result</th>
                  <th className='is-narrow has-text-centered'>Is Correct?</th>
                  <th>Notes</th>
                  <th>Tags</th>
                  <th className='is-narrow has-text-centered'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.admin.aiFeedbacks.edges.map(({ node: aiFeedback }) => (
                  <tr key={aiFeedback.id}>
                    <td className='is-narrow is-vcentered'>
                      {aiFeedback.caregiver.name}
                    </td>
                    <td className='is-narrow is-vcentered'>
                      {dayjs(aiFeedback.createdAt).format(
                        'DD-MM-YYYY HH:mm:ss'
                      )}
                    </td>
                    <td className='is-narrow has-text-centered is-vcentered'>
                      <FontAwesomeIcon
                        icon={faCircle}
                        size='xs'
                        className={clsx({
                          'has-text-success': !aiFeedback.aiResult.value,
                          'has-text-danger': aiFeedback.aiResult.value
                        })}
                      />
                    </td>
                    <td className='is-narrow has-text-centered is-vcentered'>
                      {aiFeedback.isCorrect ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className='has-text-success'
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faXmark}
                          className='has-text-danger'
                        />
                      )}
                    </td>
                    <td
                      className='is-vcentered is-clickable'
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace:
                          expandedNotesId === aiFeedback.id
                            ? 'initial'
                            : 'nowrap',
                        maxWidth: '300px'
                      }}
                      onClick={() =>
                        setExpandedNotesId(
                          expandedNotesId ? null : aiFeedback.id
                        )
                      }
                    >
                      {aiFeedback.notes}
                    </td>
                    <td
                      className='is-vcentered is-clickable'
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace:
                          expandedNotesId === aiFeedback.id
                            ? 'initial'
                            : 'nowrap',
                        maxWidth: '300px'
                      }}
                      onClick={() =>
                        setExpandedNotesId(
                          expandedNotesId ? null : aiFeedback.id
                        )
                      }
                    >
                      {aiFeedback.tags.join(', ')}
                    </td>
                    <td className='is-narrow has-text-centered is-vcentered'>
                      <button
                        className='button is-text'
                        onClick={async () => {
                          setIsShowAIResultModal(aiFeedback.aiResult)
                        }}
                      >
                        Show result
                      </button>
                      <button
                        className='button is-text'
                        onClick={async () => {
                          window.open(`/study/${aiFeedback.studyId}`, '_blank')
                        }}
                      >
                        View study
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              disabled={!data.admin.aiFeedbacks.pageInfo.hasPreviousPage}
              onClick={() => {
                if (data.admin.aiFeedbacks.pageInfo.hasPreviousPage) {
                  dispatch(prev())
                }
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={!data.admin.aiFeedbacks.pageInfo.hasNextPage}
              onClick={() => {
                if (data.admin.aiFeedbacks.pageInfo.hasNextPage) {
                  dispatch(next(data.admin.aiFeedbacks.pageInfo.endCursor))
                }
              }}
            >
              Next
            </a>
          </nav>
        </>
      )}
    </div>
  )
}

export default AdminAIFeedbacks
