if (typeof window !== 'undefined') {

/*
 * (c) 2015 Coptright Witalize LTD.
 * (r) All rights reserved
 */

/*
    bcViewer - the main viewer controller
    TODO: bcViewer not a great name, might be confusing with ECGViewer, ContextViewer, etc. Maybe call it something like bcWidget?
*/

if (!String.prototype.startsWith) { // polyfill for IE
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

bcViewer = function(params, config) {
    $(document).ready($.proxy(this._init, this, params, config));
};

/*
    Init bcViewer only after the DOM is fully loaded
    TODO: can we do few things before the DOM is ready and improve performance?
*/
bcViewer.prototype._init = function(params, config) {
    // TODO:Consider protecting against empty recordHash

    this._recordTypes = {
        ARNIKA: 0,
        PHYSIOBANK: 1,
        MOBILE: 2
    };

    this._studyTypes = {
        REST: 1,
        HOLTER: 2,
        STETHOSCOPE: 4,
        STRESS: 65536
    };

    this.modes = {
        NORMAL: 0,
        IN_APP: 1
    };

    this.realtimeModeTypes = {
        ECG:            1,
        STETHOSCOPE:    2,
        ECHO:           4
    }

    this._metadataRecordStatusCodes = {
        'STETHOSCOPE': 32,
        'ECG': 64
    };

    this.hrStatusCodes = {
        NORMAL: 0,
        ASYSTOLE: 1,
        BRADYCARDIA: 2,
        TACHCARDIA: 3
    };

    // electrodes positions enum
    this.electrodesPositions = {
        MASON_LIKAR: {
            id: 1,
            leadNames: ['I', 'II', 'Vx'],
            expandedLeadNames: ['I', 'II' , 'III', 'aVR', 'aVL', 'aVF', 'Vx'],
            expandState: 'expand3to7',
            expandedLeadNamesVet: ['I', 'II' , 'III', 'aVR', 'aVL', 'aVF'],
            expandStateVet: 'expand2to6'
        },
        EASI: {
            id: 2,
            leadNames: ['AI', 'ES', 'AS'],
            expandedLeadNames: ['I', 'II' , 'III', 'aVR', 'aVL', 'aVF', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6'],
            expandState: 'expand3to12',
            expandedLeadNamesVet: ['I', 'II' , 'III', 'aVR', 'aVL', 'aVF', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6'],
            expandStateVet: 'expand3to12'
        },
        leads12: {
            id: 5,
            leadNames: ['I', 'II', 'V5'],
            expandedLeadNames: ['I', 'II', 'III', 'aVR', 'aVL', 'aVF', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6'],
            expandState: 'expand8to12',
            expandedLeadNamesVet: ['I', 'II', 'III', 'aVR', 'aVL', 'aVF', 'V1', 'V2', 'V3', 'V4', 'V5', 'V6'],
            expandStateVet: 'expand8to12'
        }
    };

     // window orientation enum
    this.windowOrientations = {
        'UNSPECIFIED': -1,
        'LANDSCAPE': 0,
        'PORTRAIT': 1
    };

    this._viewers = {};

    this._globals = {};
    this._globals._bcViewer = this;
    this._globals._config = config;
    this._globals._utils = new bcViewer.Utils();

    this.viewerApi = new bcViewer.bcViewerApi(this);

    this._recordType = params.recordType || this._recordTypes.ARNIKA;
    this._studyType = params.studyType || this._studyTypes.HOLTER;
    if ((this._recordType === this._recordTypes.MOBILE) &&
        ((this._studyType & this._studyTypes.REST) || (this._studyType & this._studyTypes.STRESS))) {
        this._globals._dataFetcher = new bcViewer.StandaloneDataFetcher(this._globals, this._recordType);
    } else if (params.remoteMonitor){
        this.sessionId = params.sessionId;
        this._globals._dataFetcher = new bcViewer.RemoteMonitorDataFetcher(this._globals, this._recordType);
    } else if ((this.mode == this.modes.IN_APP) || (this._recordType == this._recordTypes.PHYSIOBANK)) {
        this._globals._dataFetcher = new bcViewer.WebDataFetcher(this._globals, this._recordType);
    } else {
        this._globals._dataFetcher = new bcViewer.ArnikaDataFetcher(this._globals, this._recordType);
        //this._globals._dataFetcher = new bcViewer.WebDataFetcher(this._globals, this._recordType);
    }

    this._globals.keyCodes = {
        'shift': 16,
        'leftArrow': 37,
        'rightArrow': 39,
        'esc': 27
    };

    // Keep pointers to globals on the "this" so I can call this._utils instead of this._globals._utils
    $.extend(this, this._globals);

    // Indicates if realtime mode is active
    // keep before creating this.viewer which use it
    this.isRealtimeMode = !params.recordHash && !params.recordURL;

    this.isRemoteMonitor = params.remoteMonitor;

    // Indicates if screen type is desktop
    // this.isDesktop = (this._utils.getScreenType() === this._utils.SCREEN_TYPES.DESKTOP);

    // indicate if ViewerReadyToPrint event was triggered

    this._isReadyToPrintTriggered = false;
    // bit flag- according to realtime mode types
    this.realtimeModeType = (typeof params.realtimeModeType !== 'undefined') ?
                             parseInt(params.realtimeModeType) :
                             this.realtimeModeTypes.ECG;

    // localize via js if lang is set
    if (typeof params.lang !== 'undefined') {
        this._localize(params.lang);
    }

    // localize via js if dateFormat is set
    if (typeof params.dateFormat !== 'undefined') {
        bcGlobals.locale.dateFormat = params.dateFormat;
    }

    this.electrodesPosition = params.electrodesPosition;
    this.windowOrientation = params.windowOrientation;

    this.fullDisclosureViewer = (params.fullDisclosureViewer == "true");
    this.reportMode = (bcGlobals.reportMode);

    this.showAnnotations = bcGlobals.showAnnotations ? bcGlobals.showAnnotations == "true" : true;
    this.cachedEventsData = (typeof params.cachedEventsData !== 'undefined') ? params.cachedEventsData : null;
    this.cachedRecordData = (typeof params.cachedRecordData !== 'undefined') ? params.cachedRecordData : null;

    this._forcedState = params.forcedState; // forcedState will override the url state and ignore it. The url wont be updated with state changes

    this.callbacks = params.callbacks || {};
    this.doctorStudyEdits = params.doctorStudyEdits || {};

    this.defaultPixelPerMM = this._config.PIXEL_PER_MM;

    this.mode = (typeof params.mode !== 'undefined') ? parseInt(params.mode) :  this.modes.NORMAL;

    this.view = new bcViewer.View(this._globals);

    this._recordHash;
    this._recordURL;
    this._metadata;
    this._metadataReady = false;

    this._state = {};
    this._allowedHashParams = {
        'timePosition': { inState: true, castFunc: parseInt },
        'zoomX': { inState: true, castFunc: parseInt },
        'zoomY': { inState: true, castFunc: parseInt },
        'baselineCorrection': {
            inState: true,
            castFunc: function(value) {
                return (value === 'true');
             }
        },
        'smooth': {
            inState: true,
            castFunc: function(value) {
                return (value === 'true');
             }
        },
        'measurement': {
            inState: false,
            castFunc: function(value) {
                return $.map(value.split(','), function(val) {
                    return parseInt(val);
                });
            }
        },
        'requestedChannels': {
            inState: true,
            castFunc: function(value) {
                return $.map(value.split(','), function(val) {
                    return parseInt(val);
                });
            }
        },
        'channelsSpacing': { inState: true, castFunc: parseInt },
        'magnify': { inState:true, castFunc: parseInt },
        'qrsMarkValue': {
            inState: true,
            castFunc: function(value) {
                return value;
            }
        },
    };

    if (this.reportMode) {
      delete this._allowedHashParams.measurement;
      delete this._allowedHashParams.magnify;
      delete this._allowedHashParams.channelsSpacing;
    }
    this._hashParams = {};

    // saving the initial time position in order to use it after the init cycle
    this._initialTimePosition;

    // list of viewers which didn't finish loading
    this._loadingViewers;

    this._initViewers(params);
};

/*
    init the viewers and load record
*/
bcViewer.prototype._initViewers = function(params) {
    // Create viewers
    // TODO: do we want the viewers to start asking for data from the API on initialization?
    // if not we can either move it to their onStateChange handler or add an init function
    this._viewers = {
        'main'    : new bcViewer.ECGViewer(this._globals, 'main', this._config.ecgViewer),
        'context' : new bcViewer.ContextViewer(this._globals, 'context', this._config.contextViewer),
        'timeline': new bcViewer.TimelineViewer(this._globals, 'timeline', this._config.timelineViewer),
        'hr'      : new bcViewer.HRViewer(this._globals, 'hr', this._config.HRViewer),
        'events'  : new bcViewer.EventsViewer(this._globals, 'events', this._config.eventsViewer)
    };

    this._loadingViewers = this._utils.getObjectKeys(this._viewers);

    var viewerDimensions = this.view.getDimensions();

    var defaults = params.defaults || {};

    var state = {
        'timePosition': 0,
        'zoomX': defaults.zoom || this._config.ecgViewer.DEFAULT_ZOOM.x,
        'zoomY': defaults.amplitude || this._config.ecgViewer.DEFAULT_ZOOM.y,
        'width': viewerDimensions.width,
        'height': viewerDimensions.height,
        'baselineCorrection': this._config.ecgViewer.DEFAULT_FILTERS.BASELINE,
        'smooth': this._config.ecgViewer.DEFAULT_FILTERS.SMOOTH,
        'channelsSpacing': this._config.ecgViewer.CHANNELS_SPACING.DEFAULT_SPACING,
        'magnify': this._config.PIXEL_PER_MM,
        'qrsMarkValue': defaults.qrs_intervals || 'rr',
        'userMarks': this.doctorStudyEdits.userMarks || []
    };

    this._getHashParams();
    this._updateDefaultStateFromHashParams(state);
    this._updateDefaultStateFromForcedState(state);

    this._allViewerDo('disableViewer');

    // init cycle without time position in state
    this._initialTimePosition = state.timePosition;
    delete state.timePosition;

    // Init cycle
    this.onStateChange(state, false, true);

    // Adjust viewer to mode
    this._allViewerDo('adjustToMode');

    if (!this.isRealtimeMode) {
        this._recordHash = params.recordHash;
        this._recordURL = params.recordURL;
        this.loadRecord();
    } else {
        this._allViewerDo('initRealtimeMode');

        var isStethoscopeOnlyMode = ((this.realtimeModeType & this.realtimeModeTypes.STETHOSCOPE) && !(this.realtimeModeType & this.realtimeModeTypes.ECG));
        if (isStethoscopeOnlyMode) {
            this._showAudioOnlyState();
        }
    }
};

/*
    Load a new record
    TODO: handle loading multiple records
*/
bcViewer.prototype.loadRecord = function() {
    if ((this._recordType === this._recordTypes.MOBILE) && ((this._studyType & this._studyTypes.REST) || (this._studyType & this._studyTypes.STRESS))) {
        this._dataFetcher.getRecordData(this._recordURL, $.proxy(this._getMetadata, this));
    } else {
        this._getMetadata();
    }
};

bcViewer.prototype.getRecordHash = function() {
    return this._recordHash;
};

/*
    Handle save record complete
*/
bcViewer.prototype._getMetadata = function() {
    // TODO: clear all viewers state and models when loading a new record
    // cancel all pending DataFetcher requests

    this._dataFetcher.getMetadata($.proxy(this._onGotMetadata, this));
};

/*
    Pass metadata to all viewers
*/
bcViewer.prototype._onGotMetadata = function(metadata) {
    this._metadata = metadata;

    // if not an ECG record
    if ((this.mode == this.modes.IN_APP) && !(metadata.status & this._metadataRecordStatusCodes.ECG)) {
        this._showAudioOnlyState();

        this._allViewerDo('onAllViewersReady');

        // no need to continue
        return;
    }

    this._allViewerDo('onGotMetadata', metadata);
    this.view.onGotMetadata(metadata);

    // triggering an event to update external dom entities
    $(document).trigger($.Event("GotMetadata", metadata));

    if (typeof this._state.expand2to6 !== 'undefined') {
        this._handleChannelsExpanding(this._state.expand2to6, 6, false);
    }

    if (typeof this._state.expand3to7 !== 'undefined') {
        this._handleChannelsExpanding(this._state.expand3to7, 7, false);
    }

    if (typeof this._state.expand3to12 !== 'undefined') {
        this._handleChannelsExpanding(this._state.expand3to12, 12, false);
    }

    // expand according to orientation
    if ((this.mode == this.modes.IN_APP) &&
        (parseInt(this.windowOrientation) === this.windowOrientations.PORTRAIT)) {
        var numberOfChannels = 3;
        switch (metadata.electrodesPosition) {
            case this.electrodesPositions.MASON_LIKAR.id:
                numberOfChannels = 7;
                break;
            case this.electrodesPositions.EASI.id:
            case this.electrodesPositions.leads12.id:
                numberOfChannels = 12;
                break;
        }

        this._handleChannelsExpanding(true, numberOfChannels, false);
    }

    // Init cycle finished, call onStateChange with the initial time position
    this.onStateChange({'timePosition': this._initialTimePosition}, false, false);

    if (typeof this._state.expand2to6 !== 'undefined') {
        this._handleChannelsExpanding(this._state.expand2to6, 6, true);
    }

    if (typeof this._state.expand3to7 !== 'undefined') {
        this._handleChannelsExpanding(this._state.expand3to7, 7, true);
    }

    if (typeof this._state.expand3to12 !== 'undefined') {
        this._handleChannelsExpanding(this._state.expand3to12, 12, true);
    }

    var expandState = {};
    var positions = this.electrodesPositions;

    var isVetDevice = this.isVetDevice({
        deviceNumber: this._metadata.deviceNumber,
        deviceClass: this._metadata.deviceClass
    });
    var electrodesPosition = metadata.electrodesPosition || metadata.ElectrodePlacementId;
    for (pos in positions) {
        var expandStateName = (isVetDevice || this._metadata.leadSelection === '4w6l') ? positions[pos].expandStateVet : positions[pos].expandState;
        if ((positions[pos].id === electrodesPosition) &&
            (typeof this._state[expandState] === 'undefined')) {
            expandState[expandStateName] = true;
            break;
        }
    }

    this.onStateChange(expandState, false, false);

    this._metadataReady = true;
    this._notifyOnPrintIfReady();
};

bcViewer.prototype._getHashParams = function() {
    var hashParamsArray = window.location.hash.substring(1).split('&');
    var params = {};

    for (var i=0; i<hashParamsArray.length; i++) {
        var param = hashParamsArray[i].split('=');

        if (typeof this._allowedHashParams[param[0]] !== 'undefined') {
            params[param[0]] = param[1];
        }
    }

    var ahp = this._allowedHashParams;
    $.each(params, $.proxy(function(key, value) {
        if (typeof ahp[key] !== 'undefined') {
            this._hashParams[key] = ahp[key].castFunc(value);
        }
    }, this));
};

/*
    Return hash param value
*/
bcViewer.prototype.getHashParam = function(param) {
    return this._hashParams[param];
}

/*
    Read hash from URL and update the default state
*/
bcViewer.prototype._updateDefaultStateFromHashParams = function(state) {
    var ahp = this._allowedHashParams;
    $.each(this._hashParams, $.proxy(function(key, value) {
        if (ahp[key].inState) {
            state[key] = value;
        }
    }, this));
};

/*
    Update the default state if any forced state are passed to the viewer
*/
bcViewer.prototype._updateDefaultStateFromForcedState = function(state) {
 if (typeof this._forcedState === 'undefined') return;

  var ahp = this._allowedHashParams;
  $.each(this._forcedState, $.proxy(function(key, value) {
      if (ahp[key].inState) {
          state[key] = value;
      }
  }, this));
};

/*
    Update the URL hash
*/
bcViewer.prototype.updateHash = function(params) {
    if (typeof this._forcedState !== 'undefined') return;

    $.each(params,
        $.proxy(function(key, value) {
            if (typeof this._allowedHashParams[key] !== 'undefined') {
                this._hashParams[key] = value;
            }
         },this));

    this._updateUrlHash();
};

/*
    Remove params from the URL hash
*/
bcViewer.prototype.removeParamsFromHash = function(keys) {
    for (var i=0; i < keys.length; i++) {
        delete this._hashParams[keys[i]];
    }

    this._updateUrlHash();
}

/*
    Build hash string from this.hashParams and update URL
*/
bcViewer.prototype._updateUrlHash = function() {
    var hashParamsArr = [];

    $.each(this._hashParams,
        $.proxy(function(key, value) {
            hashParamsArr.push(key + '=' + value);
        },this));

    window.location.replace(('' + window.location).split('#')[0] + '#' + hashParamsArr.join('&'));
};

/*
    Tell all viewers that the state changed
*/
bcViewer.prototype.onStateChange = function(stateChanges, shouldUpdateHash, isInitCycle) {
     shouldUpdateHash = (typeof shouldUpdateHash === 'undefined') ? true : shouldUpdateHash;
     isInitCycle = (typeof isInitCycle === 'undefined') ? false : isInitCycle;

     // remember measurement
    var measurement = this._hashParams.measurement;

    $.extend(this._state, stateChanges);

    if (typeof stateChanges.expand2to6 !== 'undefined') {
        this._handleChannelsExpanding(stateChanges.expand2to6, 6, true);
    }

    if (typeof stateChanges.expand3to7 !== 'undefined') {
        this._handleChannelsExpanding(stateChanges.expand3to7, 7, true);
    }
    if (typeof stateChanges.expand3to12 !== 'undefined') {
        this._handleChannelsExpanding(stateChanges.expand3to12, 12, true);
    }
    if (typeof stateChanges.expand8to12 !== 'undefined') {
        this._handleChannelsExpanding(stateChanges.expand8to12, 12, true);
    }
    if ((typeof stateChanges.channelsSpacing !== 'undefined' || typeof stateChanges.zoomY !== 'undefined') && this._metadata) {
        this.setSpacingButtonsState();
        this.adjustHeightToChannels(this._metadata.NumberOfChannels);
    }

    if (typeof stateChanges.magnify !== 'undefined') {
        this.setMagnifyButtonsState();
    }

    // Make sure no ECG data request is sent to the server until all the viewers got the onStateChange event
    this._dataFetcher.disableECGDataRequests();

    this._allViewerDo('onStateChange', stateChanges, isInitCycle);

    // Re-enable the requests
    this._dataFetcher.enableECGDataRequests();

    if (shouldUpdateHash) {
        this.updateHash(this._state);
    }

    // set measurement
    if (measurement) {
        this._hashParams.measurement = measurement;
        this.updateHash({
            'measurement': [measurement[0], measurement[1], measurement[2], measurement[3]]
        });
        this._passParamsToViewers();
    }
};

/*
    Run a specific function on all viewers
*/
bcViewer.prototype._allViewerDo = function(func) {
    var outArgs = arguments;
    $.each(this._viewers, function(name, viewer) {
        var args = Array.prototype.slice.call(outArgs);
        args.shift();
        viewer[func] && viewer[func].apply(viewer, args);
    });
};

/*
    Request state changes - usually called from the view due to requests from the user
*/
bcViewer.prototype.onStateChangeRequest = function(stateChanges) {
    if (typeof this._viewers !== 'undefined') {
      this.onStateChange(stateChanges);
    }
};

/*
    Return viewer state
*/
bcViewer.prototype.getViewerState = function(viewerName) {
    if (typeof this._viewers[viewerName] === 'undefined') {
        return {};
    }

    return this._viewers[viewerName].getState();
};

/*
    Braodcast drag move to all viewers
*/
bcViewer.prototype.onDragMove = function(timePosition) {
    this._allViewerDo('onDragMove', timePosition);
};

/*
    Broadcast fetching start to all viewers
*/
bcViewer.prototype.onDataFetchStart = function() {
    this._allViewerDo('onDataFetchStart');
};

/*
    Broadcast fetching end to all viewers
*/
bcViewer.prototype.onDataFetchEnd = function() {
    this._allViewerDo('onDataFetchEnd');
};

/*
    Return the ECG main Viewer width
*/
bcViewer.prototype.getMainWidth = function() {
    // viewer._state and not viewer.getState() because we dont want the state
    // in the model but the state in the viewer which is updated before we gor the data
    return (this._viewers['main']) ? this._viewers['main']._state.width : 0;
};

/*
    Called when a viewer finished loading and is ready.
    When all viewers finished, broadcast loading end to all viewers
*/
bcViewer.prototype.onViewerReady = function(viewerName) {
    var index = this._loadingViewers.indexOf(viewerName);
    this._loadingViewers.splice(index, 1);

    if (this._loadingViewers.length === 0) {
        this._allViewerDo('onAllViewersReady');
        this._passParamsToViewers();
        this._notifyOnPrintIfReady();
        $(document).trigger($.Event('ViewerReady'));

        window.dispatchEvent(new Event('ViewerReadyVanilla'));
    }
};

/*
    pass params (which are not state params) to the viewers
*/
bcViewer.prototype._passParamsToViewers = function() {
    var params = $.extend(params, this._hashParams);

    // verify all 4 needed points are given
    if (typeof params.measurement !== 'undefined') {
        // filter array to comtain only numeric values
        params.measurement = $.grep(params.measurement, this._utils.isNumber);

        // if all 4 params are numeric
        if (params.measurement.length === 4) {
            params.measurement = {
                'start': {
                    'x' : params.measurement[0],
                    'y': params.measurement[1]
                },
                'end': {
                    'x':  params.measurement[2],
                    'y': params.measurement[3]
                }
            }
        } else {
            delete params.measurement;
        }
    }

    this._allViewerDo('onGotHashParams', params);
};

/*
    Return the record type
*/
bcViewer.prototype.getRecordType = function() {
    return this._recordType;
};

/*
    Return the study type
*/
bcViewer.prototype.getStudyType = function() {
    return this._studyType;
};

/*
    Adjust the viewer to new number of channels
*/
bcViewer.prototype._handleChannelsExpanding = function(expand, expandedNumberOfChannels, changeHeight) {
    if (!this._metadata) return;

    if (expand) {
        this._metadata.NumberOfChannels = expandedNumberOfChannels;
        changeHeight && this.adjustHeightToChannels(expandedNumberOfChannels);
    }
    if ((this._metadata.NumberOfChannels === expandedNumberOfChannels) && !expand) {
        this._metadata.NumberOfChannels = 3;
        changeHeight && this.adjustHeightToChannels(3);
    }
};

/*
    Adjust viewer height according to number of channels and the zoomY
*/
bcViewer.prototype.adjustHeightToChannels = function(numOfChannels) {
    if (typeof this._config.BC_VIEWER_HEIGHT === 'undefined') {
        return;
    }

    // The default channel height in MV
    var defaultChannelHeightMV = 2.75;

    // Add the spacing value and get the needed channel height in MV
    var channelHeightWithSpacingMV = defaultChannelHeightMV + this._state.channelsSpacing * this._config.ecgViewer.CHANNELS_SPACING.MV_PER_STEP;

    // Convert the channel height from MV to pixels. (but if zoomY = 2 calculate it as if zoomY = 5 because with zoomY = 2 the canvas is too small)
    var channelHeightPX = channelHeightWithSpacingMV * Math.max(this._state.zoomY, 5) * this._config.PIXEL_PER_MM;

    // Calculate the viewer height in pixels (numOfChannels+1 because we want some margin. margin size = 1 channel height)
    var viewerHeight = channelHeightPX*(numOfChannels+1) + 200; // 200 is ~height of other elements in bc_viewer + some margin

    if (bcGlobals.printMode) {
        viewerHeight = Math.min(viewerHeight, 1110);
    }
    this.view.resize({height: viewerHeight});
};

bcViewer.prototype.setMagnifyButtonsState = function() {
    $('.bcv_dropdownmenu_zoom_in').removeClass('ui-state-disabled');
    $('.bcv_dropdownmenu_zoom_out').removeClass('ui-state-disabled');

    var config = this._config.ecgViewer.MAGNIFY;
    if (this._state.magnify >= config.MAX) {
        $('.bcv_dropdownmenu_zoom_in').addClass('ui-state-disabled');
    } else if (this._state.magnify <= config.MIN) {
        $('.bcv_dropdownmenu_zoom_out').addClass('ui-state-disabled');
    }
};

bcViewer.prototype.setSpacingButtonsState = function() {
    $('.bcv_decrease_spacing').removeAttr('disabled');
    $('.bcv_increase_spacing').removeAttr('disabled');

    $('.bcv_dropdownmenu_increase_spacing').removeClass('ui-state-disabled');
    $('.bcv_dropdownmenu_decrease_spacing').removeClass('ui-state-disabled');

    var maxSteps = this._config.ecgViewer.CHANNELS_SPACING.MAX_STEPS;
    if (this._state.channelsSpacing >= maxSteps) {
        $('.bcv_increase_spacing').attr('disabled', 'disabled');
        $('.bcv_dropdownmenu_increase_spacing').addClass('ui-state-disabled');
        $('.bcv_increase_spacing').mouseout(); // fix tooltip issue
    }

    if (this._state.channelsSpacing <= -maxSteps) {
        $('.bcv_decrease_spacing').attr('disabled','disabled');
        $('.bcv_dropdownmenu_decrease_spacing').addClass('ui-state-disabled');
        $('.bcv_decrease_spacing').mouseout(); // fix tooltip issue
    }
};

bcViewer.prototype.onChannelsSpacingChangeRequest = function(factor) {
    this.onStateChangeRequest({
        channelsSpacing: this._state.channelsSpacing + factor
    });
};

/*
    Trigger ViewerReadyToPrint event if ready to print
*/
bcViewer.prototype._notifyOnPrintIfReady = function() {
    if (this._isReadyToPrintTriggered) return; // trigger only once
    if (this._loadingViewers.length !== 0) return; // wait for all viewers to be ready
    if (!this._metadataReady) return; // wait for metadata

    this._isReadyToPrintTriggered = true;
    if (bcGlobals.printMode) {
        $('.bcv_main_canvas').hide();
        var mainCanvas = $('.bcv_main_canvas');
        var url = $('.bcv_main_canvas')[0].toDataURL();
        var canvasImage = $('<img></img>').attr('src', url).attr('id','bcv_main_canvas_image');
        $('.bcv_main_canvas').parent().append(canvasImage);
    }

    this.viewerApi.onViewerReadyToPrint();
};

/*
    Show audio only state
*/
bcViewer.prototype._showAudioOnlyState = function() {
    $$('#bc_viewer').addClass('audio_only');
    var notification =  $$('.bcv_lightbox_content');
    var notificationLeft = ($$('.bcv_lightbox').width()/2) - (notification.width()/2);
    var notificationTop = ($$('.bcv_lightbox').height()/2) - (notification.height()/2);
    notification.css({'top': notificationTop, 'left': notificationLeft});
};

/*
    Translte strings
*/
bcViewer.prototype._localize = function(lang) {
    for (var item in bcViewer.lang[lang]) {
        $(bcViewer.lang[lang][item].selector).text(bcViewer.lang[lang][item].string);
    }

    $.extend(true, bcGlobals.locale, bcViewer.lang[lang].locale);
};


/*
    Indicate if the device is veterinarian device.

    We have few different FW versions.
    Some versions doesnt have any name convension for device type.
    Some versions have the device type inside the device name\class.
    Some verions have the device type inside the device number.
    This function checks in the device class and the device number for the type.
 */
bcViewer.prototype.isVetDevice = function(device) {
    if (device.deviceNumber && device.deviceNumber.toLowerCase().startsWith('v')) return true;

    var deviceClassParts = (device.deviceClass) ? device.deviceClass.split(' ') : [];
    if (deviceClassParts.length > 1 && deviceClassParts[deviceClassParts.length-1].toLowerCase().startsWith('v')) return true;

    return false;
};

/*
    indicate if full screen is available
*/
bcViewer.prototype.isFullscreenAvailable = function() {
    return ((this.mode != this.modes.IN_APP) && !this.isRemoteMonitor &&
           (!((window.location.href.indexOf('/viewer') > -1))));
};

/*
    jump to the next visible window
*/
bcViewer.prototype.jumpToNextWindow = function() {
    this._nextWindowReady = false;
    return this._viewers['main']._view.jumpToNextWindow();
};

/*
    jump to time position
*/
bcViewer.prototype.jumpToTimePosition = function(timePosition) {
  return this._viewers['main'].onStateChangeRequest({
    'timePosition': timePosition
  });
};

/*
    on next window ready to print
*/
bcViewer.prototype.fullDisclosureFrameReady = function() {
    if (this._nextWindowReady) return;
    this._nextWindowReady = true;
    this.viewerApi.onViewerReadyToPrint();
};

window.bcViewer = bcViewer

}
