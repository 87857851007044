import React, { useState } from 'react'
import Modal from './Modal'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import Loader from './Loader'

const STUDY_AI_ANALIZE = gql`
  mutation STUDY_AI_ANALIZE($studyId: ID!, $caregiverId: ID!) {
    studyAIAnalyze(studyId: $studyId, caregiverId: $caregiverId) {
      id
    }
  }
`

export default function CreateAIAnalysis({
  study,
  currentUser,
  locale,
  onExit,
  getAIResult
}) {
  const [isWaiting, setIsWaiting] = useState(false)
  const [isNoResult, setIsNoResult] = useState(false)

  const [aiAnalyzeStudy, { error }] = useMutation(STUDY_AI_ANALIZE)

  async function submit() {
    try {
      setIsWaiting(true)

      await aiAnalyzeStudy({
        variables: {
          studyId: study.id,
          caregiverId: currentUser.id
        }
      })
      let isAIResult = false
      let i = 0

      while (!isAIResult && i < 4) {
        await new Promise((resolve) => {
          setTimeout(resolve, 12000)
        })
        isAIResult = !!(await getAIResult())
        i++
      }

      setIsWaiting(false)

      if (isAIResult) {
        onExit()
      } else {
        setIsNoResult(true)
      }
    } catch (e) {
      void 0
    }
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={isWaiting}>
      <label className='label is-medium'>{locale.ai_analyze_study}</label>
      <hr />
      {study.anonymousPatientData.animal.type === 'Other' ? (
        <div className='level-left'>
          <div className='level-item'>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              size='lg'
              className='has-text-warning'
            />
          </div>
          <div className='level-item' style={{ whiteSpace: 'pre-wrap' }}>
            {locale.ai_other_type_warning}
          </div>
        </div>
      ) : null}
      <div className='field mt-5'>
        <div className='level'>
          <div className='level-left'>
            {error ||
              (isNoResult && (
                <div className='level-item has-text-danger'>
                  {error || 'AI Analysis failed, Please contact administrator.'}
                </div>
              ))}
            {isWaiting && (
              <div className='level-item'>
                <Loader size='lg' />
              </div>
            )}
            {isWaiting && (
              <div className='level-item has-text-success'>
                In progress, please wait...
              </div>
            )}
          </div>
          <div className='level-right'>
            <button
              className={clsx('button is-primary level-item', {
                'is-loading': isWaiting
              })}
              disabled={isWaiting}
              onClick={async () => submit()}
            >
              Analyze
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
