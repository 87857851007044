import React, { useState } from 'react'
import AdminReferralsConfig from './AdminReferralsConfig'
import AdminReferralsConfigUsers from './AdminReferralsConfigUsers'
import clsx from 'clsx'

const AdminReferralsConfigMain = () => {
  const [tab, setTab] = useState(null)

  return (
    <div>
      <div className='mt-5'>
        <div className='tabs'>
          <ul>
            <li
              className={clsx({
                'is-active': tab === 'configs' || !tab
              })}
            >
              <a onClick={() => setTab('configs')}>Configs</a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'users'
              })}
            >
              <a onClick={() => setTab('users')}>Users</a>
            </li>
          </ul>
        </div>

        {(!tab || tab == 'configs') && (
          <div>
            <AdminReferralsConfig></AdminReferralsConfig>
          </div>
        )}
        {tab == 'users' && (
          <div>
            <AdminReferralsConfigUsers></AdminReferralsConfigUsers>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminReferralsConfigMain
