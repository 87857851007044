import React, { useEffect, useReducer, useContext } from 'react'
import { gql, useMutation } from '@apollo/client'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircle,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import AuthContext from './AuthContext'
import ModalCard from './ModalCard'
import { useLocalization } from './LocalizationProvider'
import { DATE_FORMAT_CONVERTER, isRTL } from '../../utils/utils'

const TAGS = {
  'Ectopic Beats': {
    APCs: 'APCs',
    VPCs: 'VPCs'
  },
  Arrhythmia: {
    Afib: 'Afib',
    VTach: 'VTach',
    SVTs: 'SVTs',
    Vfib: 'Vfib',
    Bradycardia: 'Bradycardia',
    Pathologic_Arrhythmia: 'Other Arrhythmias'
  },
  Blocks: {
    AVBlocks: 'AV Blocks',
    BBBlocks: 'Bundle Branch Blocks'
  },
  Morphology: {
    P_Wave_Abnormal: 'P-Wave abnormality',
    Abnormal_QRS: 'QRS abnormality'
  },
  MEA: {
    MEA_Left: 'MEA left',
    MEA_Right: 'MEA right'
  },
  Other: {
    ST_Elevation: 'ST Elevation',
    ST_Depression: 'ST Depression',
    T_Wave_Inversion: 'T-Wave Inversion',
    Hyperkalaemia: 'Hyperkalaemia',
    Hypokalaemia: 'Hypokalaemia'
  }
}

const TAG_GROUPS = Object.entries(TAGS)

const CREATE_AI_FEEDBACK = gql`
  mutation CREATE_AI_FEEDBACK(
    $studyId: ID!
    $clinicId: ID!
    $caregiver: CaregiverConnectionInput!
    $clinic: ClinicConnectionInput!
    $aiResult: AIResultConnectionInput!
    $input: StudyAIFeedbackCreateInput!
  ) {
    studyAIFeedbackCreate(
      studyId: $studyId
      caregiver: $caregiver
      clinic: $clinic
      aiResult: $aiResult
      input: $input
    ) {
      id
    }
    query {
      study(id: $studyId) {
        id
        aiFeedback(clinicId: $clinicId) {
          id
          updatedAt
          isCorrect
          notes
          tags
          caregiver {
            name
          }
        }
      }
    }
  }
`

const UPDATE_AI_FEEDBACK = gql`
  mutation UPDATE_AI_FEEDBACK(
    $studyId: ID!
    $clinicId: ID!
    $input: StudyAIFeedbackUpdateInput!
  ) {
    studyAIFeedbackUpdate(input: $input) {
      id
    }
    query {
      study(id: $studyId) {
        id
        aiFeedback(clinicId: $clinicId) {
          id
          updatedAt
          isCorrect
          notes
          tags
          caregiver {
            name
          }
        }
      }
    }
  }
`

const initialState = {
  isCorrect: true,
  notes: '',
  tags: []
}

function reducerBuilder(state, [key, ...keys], payload) {
  if (keys.length === 0) {
    return {
      ...state,
      [key]: payload
    }
  }
  return {
    ...state,
    [key]: reducerBuilder(state[key], keys, payload)
  }
}

function reducer(state, action) {
  if (action.type === 'RESET') return action.payload
  return reducerBuilder(state, action.key.split('.'), action.payload)
}

const AIFeedbackModal = ({
  onExit,
  aiResult,
  aiFeedback,
  studyId,
  animalType,
  prefilledData
}) => {
  const currentUser = useContext(AuthContext)
  const { locale } = useLocalization()
  const [aiFeedbackData, dispatchAIFeedbackData] = useReducer(
    reducer,
    initialState
  )

  useEffect(() => {
    dispatchAIFeedbackData({
      type: 'RESET',
      payload: {
        ...initialState,
        ...(prefilledData
          ? {
              notes: prefilledData.notes || '',
              tags: prefilledData.tags || []
            }
          : {}),
        ...(aiFeedback
          ? {
              isCorrect: aiFeedback.isCorrect,
              notes: aiFeedback.notes,
              tags: aiFeedback.tags
            }
          : {})
      }
    })
  }, [studyId])

  const [
    createAIFeedback,
    { loading: createAIFeedbackLoading, error: createAIFeedbackError }
  ] = useMutation(CREATE_AI_FEEDBACK)
  const [
    updateAIFeedback,
    { loading: updateAIFeedbackLoading, error: updateAIFeedbackError }
  ] = useMutation(UPDATE_AI_FEEDBACK)

  const hasAIFeedback = !!aiFeedback

  const defaultData = aiFeedback || initialState

  const hasIsCorrectChanged = defaultData.isCorrect !== aiFeedbackData.isCorrect
  const hasNotesChanged = defaultData.notes !== aiFeedbackData.notes
  const hasTagsChanged =
    JSON.stringify(defaultData.tags.sort()) !==
    JSON.stringify(aiFeedbackData.tags.sort())

  const hasFormChanged =
    hasIsCorrectChanged || hasNotesChanged || hasTagsChanged

  return (
    <ModalCard
      onClose={() => {
        onExit && onExit()
        prefilledData && prefilledData.onExit && prefilledData.onExit()
      }}
      title={locale.ai_result_feedback_title}
      additionalStyles={{ boxSizing: 'border-box' }}
      actions={
        <>
          {createAIFeedbackError ||
            (updateAIFeedbackError && (
              <span className='has-text-danger'>
                {createAIFeedbackError || updateAIFeedbackError}
              </span>
            ))}
          <button
            className='button is-ghost'
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              onExit && onExit()
              prefilledData && prefilledData.onExit && prefilledData.onExit()
            }}
          >
            {locale.cancel}
          </button>
          <button
            className={clsx('button is-primary', {
              'is-loading': createAIFeedbackLoading || updateAIFeedbackLoading
            })}
            disabled={
              !hasFormChanged ||
              createAIFeedbackLoading ||
              updateAIFeedbackLoading
            }
            onClick={async () => {
              const createdAt = new Date().toISOString()

              if (hasAIFeedback) {
                await updateAIFeedback({
                  variables: {
                    input: {
                      id: aiFeedback.id,
                      updatedAt: createdAt,
                      ...aiFeedbackData
                    },
                    studyId,
                    clinicId: currentUser.clinic.id
                  }
                })
              } else {
                await createAIFeedback({
                  variables: {
                    studyId,
                    clinicId: currentUser.clinic.id,
                    caregiver: {
                      id: currentUser.id,
                      name: currentUser.settings.displayName,
                      email: currentUser.email
                    },
                    clinic: {
                      id: currentUser.clinic.id,
                      name: currentUser.clinic.name
                    },
                    aiResult: {
                      id: aiResult.id,
                      value: aiResult.value
                    },
                    input: {
                      id: uuidv4(),
                      createdAt,
                      ...aiFeedbackData
                    }
                  }
                })
              }
              onExit && onExit()
              prefilledData && prefilledData.onExit && prefilledData.onExit()
            }}
          >
            {locale.save}
          </button>
        </>
      }
    >
      <div className='mb-6'>
        <span className='title is-5 mr-3'>{locale.ai_result}</span>
        <FontAwesomeIcon
          icon={faCircle}
          size='xs'
          className={clsx({
            'has-text-success': !aiResult.value,
            'has-text-danger': aiResult.value
          })}
        />
        {animalType === 'Other' ? (
          <div className='level-left mt-4'>
            <div className='level-item'>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                size='lg'
                className='has-text-warning'
              />
            </div>
            <div className='level-item' style={{ whiteSpace: 'pre-wrap' }}>
              {locale.ai_other_type_warning}
            </div>
          </div>
        ) : null}
      </div>
      <div className='mb-6'>
        <div className='mb-4'>
          <span className='title is-5'>{locale.ai_result_verification}</span>
        </div>
        <div className='radios'>
          <label className='radio'>
            <input
              type='radio'
              name='aiResultVerification'
              value='yes'
              checked={aiFeedbackData.isCorrect}
              onChange={() =>
                dispatchAIFeedbackData({
                  key: 'isCorrect',
                  payload: true
                })
              }
              className='mr-2'
            />
            {locale.yes}
          </label>
          <label className='radio'>
            <input
              type='radio'
              name='aiResultVerification'
              value='no'
              checked={!aiFeedbackData.isCorrect}
              onChange={() =>
                dispatchAIFeedbackData({
                  key: 'isCorrect',
                  payload: false
                })
              }
              className='mr-2'
            />
            {locale.no}
          </label>
        </div>
      </div>
      <div className='mb-6'>
        <div className='mb-4'>
          <span className='title is-5'>{locale.ai_notes}</span>
        </div>
        <textarea
          className='textarea mt-2'
          maxLength={10000}
          value={aiFeedbackData.notes}
          style={{
            direction:
              aiFeedbackData.notes && isRTL(aiFeedbackData.notes[0])
                ? 'rtl'
                : 'ltr'
          }}
          onChange={(e) =>
            dispatchAIFeedbackData({
              key: 'notes',
              payload: e.target.value
            })
          }
        />
        <div className='is-flex is-justify-content-space-between'>
          <span id='report_signature_creator'>
            {hasAIFeedback ? aiFeedback.caregiver.name : ''}
          </span>
          <span id='report_signature_date' className='report_signature_date'>
            {hasAIFeedback
              ? dayjs(aiFeedback.updatedAt).format(
                  DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                )
              : ''}
          </span>
        </div>
      </div>
      <div className='ai-feedback-section'>
        <div className='mb-4'>
          <span className='title is-5'>{locale.ai_feedback_findings}</span>
        </div>
        <table
          className='table is-striped is-fullwidth'
          style={{ border: '1px solid hsl(0deg, 0%, 86%)' }}
        >
          <tbody>
            {TAG_GROUPS.map(([category, tags]) => (
              <tr key={category}>
                <th className='p-4' style={{ fontSize: '0.85rem' }}>
                  {category}
                </th>
                <td
                  className='is-centered is-vcentered p-4'
                  style={{ fontSize: '0.85rem' }}
                >
                  <div className='checkboxes'>
                    {Object.entries(tags).map(([value, label]) => (
                      <label
                        key={value}
                        className='checkbox is-flex is-align-items-center'
                      >
                        <input
                          type='checkbox'
                          className='mr-1'
                          checked={aiFeedbackData.tags.includes(value)}
                          onChange={(e) => {
                            dispatchAIFeedbackData({
                              key: 'tags',
                              payload: e.target.checked
                                ? aiFeedbackData.tags.concat(value)
                                : aiFeedbackData.tags.filter(
                                    (item) => item !== value
                                  )
                            })
                          }}
                        ></input>
                        {label}
                      </label>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ModalCard>
  )
}

export default AIFeedbackModal
