import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { navigate } from '@reach/router' // eslint-disable-line import/no-unresolved
// import usePagination from '../hooks/usePagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import AuthContext from './AuthContext'
import { GET_REFERRALS } from './queries'
import Layout from './Layout'
import Loader from './Loader'
import ReferralCancel from './ReferralCancel'
import {
  DATE_FORMAT_CONVERTER,
  REFERRAL_TYPES_CONVERTER,
  isAuthorizedAction,
  localizePatientName
} from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'
import SomethingWentWrong from './SomethingWentWrong'

const FIRST = 50

const Referrals = () => {
  // const [{ before, after, first }, setPagination] = usePagination(FIRST)
  const currentUser = useContext(AuthContext)
  const { locale } = useLocalization()
  const [referralToCancel, setReferralToCancel] = useState(null)
  const { loading, error, data } = useQuery(GET_REFERRALS, {
    variables: {
      id: currentUser.id,
      status: 'Pending',
      queuesReferralsStartStatus: 'PendingInQueue',
      queuesReferralsEndStatus: 'PaymentPending',
      first: FIRST /*, first, before, after */
    },
    skip: !isAuthorizedAction(
      currentUser.clinic.authorizedActions,
      'Clinic:targetReferrals'
    )
  })

  if (error) return <SomethingWentWrong />

  const referrals = loading
    ? []
    : data.clinic.targetReferrals.edges
        .concat(
          data.clinic.referralsQueues
            .map(({ referrals: { edges } }) => edges)
            .flat()
        )
        .map(({ node }) => node)
        .sort((r1, r2) => (r1.createdAt < r2.createdAt ? 1 : -1))

  const hasReferralsInQueue = referrals.some(
    (referral) => !!referral.targetQueue
  )

  return (
    <Layout>
      {isAuthorizedAction(
        currentUser.clinic.authorizedActions,
        'Clinic:targetReferrals'
      ) && (
        <div className='section'>
          <div className='container'>
            {loading ? (
              <Loader />
            ) : (
              <>
                {referralToCancel && (
                  <ReferralCancel
                    referral={referralToCancel}
                    isCancelByTarget
                    onComplete={() => {
                      setReferralToCancel(null)
                    }}
                    onExit={() => setReferralToCancel(null)}
                    onUpdate={(
                      cache,
                      {
                        data: {
                          studyReferralCancel: { id }
                        }
                      }
                    ) => {
                      cache.modify({
                        id: cache.identify(currentUser.clinic),
                        fields: {
                          targetReferralsTotal: (value) => value - 1
                        }
                      })
                      cache.modify({
                        id: cache.identify(currentUser.clinic),
                        fields: {
                          targetReferrals: ({ edges, pageInfo }) => ({
                            edges: edges.filter(({ node }) => node.id !== id),
                            pageInfo
                          })
                        }
                      })
                      cache.modify({
                        id: `Study:${referralToCancel.studyId}`,
                        fields: {
                          referrals: (referrals) =>
                            referrals.filter((r) => r.id !== id)
                        }
                      })
                    }}
                  ></ReferralCancel>
                )}
                <table className='table is-fullwidth is-hoverable'>
                  <thead>
                    <tr>
                      <th>{locale.ordered_by}</th>
                      {hasReferralsInQueue && <th>{locale.queue_name}</th>}
                      <th>{locale.studies.patient_name}</th>
                      <th>{locale.studies.type}</th>
                      <th>{locale.studies.date}</th>
                      <th>{locale.status}</th>
                      {isAuthorizedAction(
                        currentUser.clinic.authorizedActions,
                        'Clinic:ai'
                      ) && <th className='has-text-centered'>{locale.ai}</th>}
                      <th style={{ paddingLeft: 'calc(0.75em + 1em + 1px)' }}>
                        {locale.report_requests.actions}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {referrals.map((referral) => (
                      <tr
                        key={referral.studyId}
                        className='c-hand'
                        onClick={() =>
                          navigate(
                            `/study/${referral.studyId}` +
                              (referral.isTargetIsQueue
                                ? `/assign/${referral.id}`
                                : '')
                          )
                        }
                      >
                        <td className='is-vcentered'>
                          {referral.sourceCaregiver.name}
                        </td>
                        {hasReferralsInQueue && (
                          <td>
                            {referral.targetQueue
                              ? referral.targetQueue.name
                              : ''}
                          </td>
                        )}
                        <td className='is-vcentered'>
                          {localizePatientName(referral.patient.name, locale)}
                        </td>
                        <td className='is-vcentered'>
                          {
                            locale.studies.reports[
                              REFERRAL_TYPES_CONVERTER[referral.referralType]
                            ]
                          }
                        </td>
                        <td className='is-vcentered'>
                          {dayjs(referral.createdAt).format(
                            `${
                              DATE_FORMAT_CONVERTER[
                                currentUser.settings.dateFormat
                              ]
                            } HH:mm:ss`
                          )}
                        </td>
                        <td className='is-vcentered'>
                          {locale.referrals.status[referral.status]}{' '}
                        </td>
                        {isAuthorizedAction(
                          currentUser.clinic.authorizedActions,
                          'Clinic:ai'
                        ) && (
                          <td className='has-text-centered is-vcentered'>
                            {referral.aiResult && (
                              <FontAwesomeIcon
                                icon={faCircle}
                                size='xs'
                                className={clsx({
                                  'has-text-success': !referral.aiResult.value,
                                  'has-text-danger': referral.aiResult.value
                                })}
                              />
                            )}
                          </td>
                        )}
                        <td className='is-vcentered'>
                          {referral.status !== 'Done' &&
                          !referral.targetQueue &&
                          referral.price === 0 ? (
                            <button
                              className='button is-text'
                              onClick={(e) => {
                                e.stopPropagation()
                                setReferralToCancel(referral)
                              }}
                            >
                              {locale.report_requests.cancel_report}
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <nav
                  className='pagination'
                  role='navigation'
                  aria-label='pagination'
                >
                  <a
                    className='pagination-previous'
                    disabled={
                      !data.clinic.targetReferrals.pageInfo.hasPreviousPage
                    }
                    onClick={() => {
                      if (
                        data.clinic.targetReferrals.pageInfo.hasPreviousPage
                      ) {
                        setPagination(
                          data.clinic.targetReferrals.pageInfo.startCursor,
                          null
                        )
                      }
                    }}
                  >
                    {locale.previous}
                  </a>
                  <a
                    className='pagination-next'
                    disabled={!data.clinic.targetReferrals.pageInfo.hasNextPage}
                    onClick={() => {
                      if (data.clinic.targetReferrals.pageInfo.hasNextPage) {
                        setPagination(
                          null,
                          data.clinic.targetReferrals.pageInfo.endCursor
                        )
                      }
                    }}
                  >
                    {locale.next}
                  </a>
                </nav> */}
              </>
            )}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Referrals
