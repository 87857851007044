import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import {
  GET_REFERRALS_CONFIGS_WITH_DATA,
  GET_REFERRALS_CONFIG
} from '../queries'
import Modal from '../Modal'
import clsx from 'clsx'

const UPDATE_REFERRAL_CONFIG_UNAVAILABLE_UNTIL = gql`
  mutation UPDATE_REFERRAL_CONFIG_UNAVAILABLE_UNTIL(
    $id: ID!
    $group: String!
    $studyProtocol: StudyProtocol!
    $referralType: ReferralType!
    $input: UserUpdateInReferralsConfigInput!
  ) {
    userUpdateInReferralsConfig(
      id: $id
      group: $group
      studyProtocol: $studyProtocol
      referralType: $referralType
      input: $input
    ) {
      id
    }
  }
`

const UpdateReferralConfigUnavailableUntil = ({
  configsToUpdate,
  onExit,
  onComplete
}) => {
  const [inProgress, setProgress] = useState(false)

  const [isUnavailableInput, setIsUnavailableInput] = useState(
    configsToUpdate[0].unavailableUntil
  )
  const [unavilableUntilInput, setUnavilableUntilInput] = useState(
    configsToUpdate[0].unavailableUntil || ''
  )

  const [userUpdateInReferralsConfig, { loading: isEditing }] = useMutation(
    UPDATE_REFERRAL_CONFIG_UNAVAILABLE_UNTIL,
    {
      refetchQueries: [
        {
          query: GET_REFERRALS_CONFIGS_WITH_DATA
        }
      ].concat(
        configsToUpdate.map((config) => {
          return {
            query: GET_REFERRALS_CONFIG,
            variables: { referralsConfigId: config.configId }
          }
        })
      ),
      awaitRefetchQueries: true
    }
  )

  async function submit() {
    setProgress(true)
    await Promise.all(
      configsToUpdate.map((config) =>
        userUpdateInReferralsConfig({
          variables: {
            id: config.caregiver.id,
            group: config.configId,
            studyProtocol: config.studyProtocol,
            referralType: config.referralType,
            input: {
              updatedAt: new Date().toISOString(),
              unavailableUntil: isUnavailableInput
                ? unavilableUntilInput
                : undefined,
              isDeleteUnavailableUntil: !isUnavailableInput
            }
          }
        })
      )
    )

    setProgress(false)
    onComplete()
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Caregiver \ Queue:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              {`${configsToUpdate[0].caregiver.name}${
                !configsToUpdate[0].isQueue
                  ? ` (${configsToUpdate[0].caregiver.email})`
                  : ''
              }`}
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Unavailable:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <label className='checkbox'>
                <input
                  type='checkbox'
                  checked={isUnavailableInput}
                  onChange={(e) => setIsUnavailableInput(e.target.checked)}
                  className='mr-2'
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      {isUnavailableInput && (
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Unavilable until:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>
                <div className='select'>
                  <input
                    className='input'
                    type='date'
                    value={unavilableUntilInput}
                    onChange={(e) => setUnavilableUntilInput(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='level'>
        <div className='level-left'>
          <div className='level-item'></div>
        </div>
        <div className='level-right'>
          <div className='level-item'>
            <button
              className={clsx('button is-primary', {
                'is-loading': inProgress || isEditing
              })}
              disabled={isUnavailableInput && unavilableUntilInput === ''}
              onClick={async () => submit()}
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateReferralConfigUnavailableUntil
